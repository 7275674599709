import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { DataKey } from 'api-clients/monolith';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { isHiringManagerStandardQuestion } from 'components/FormBuilder/helpers';
import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';
import { GetGroupedDataKeys } from 'containers/WorkflowEditor/RulesEditDataContextProvider/RulesEditDataContextProvider';

import { DocumentSigningRuleProps } from '../../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../../types';
import messages from './messages';

interface DataKeySelectProps extends SharedConditionProps {
  isStandard2?: boolean;
  onlyReturnFileKeys?: boolean;
}

export const DataKeySelect: FC<DataKeySelectProps> = React.memo(
  ({
    setRules,
    setDocumentSigningRules,
    ruleId,
    condition,
    isStandard2,
    onlyReturnFileKeys,
    errors,
  }) => {
    const intl = useIntl();
    const { extra } = condition;

    const { data_keys: dataKeys } = useContext(RulesEditDataContext);

    const standardField = isStandard2 ? 'standard2' : 'standard';
    const keyField = isStandard2 ? 'key2' : 'key';

    const onChangeDataKey = (dataKey: DataKey) => {
      const rulesSetter = setRules ?? setDocumentSigningRules;

      if (rulesSetter) {
        rulesSetter(
          produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
            const draftConditions =
              draftRules[ruleId]?.condition_set_attributes
                ?.conditions_attributes ?? [];

            const idx = draftConditions.findIndex(
              cond => cond.id === condition.id,
            );

            const { predefined, key } = dataKey;

            draftConditions[idx].extra[standardField] =
              predefined || isHiringManagerStandardQuestion(key);
            draftConditions[idx].extra[keyField] = key;
            draftConditions[idx].extra.value = '';
            if (!isStandard2) {
              draftConditions[idx].extra.key2 = undefined;
            }
          }),
        );
      }
    };

    const selectedKey = isStandard2
      ? { key: extra?.key2, predefined: extra?.standard2 }
      : { key: extra?.key, predefined: extra?.standard };
    const selectDisplayValue = selectedKey.key
      ? {
          key: `${selectedKey.key} (${
            selectedKey.predefined ? 'standard' : 'custom'
          })`,
        }
      : null;

    const errorMessage =
      !selectedKey.key && getConditionErrorMessage(keyField, errors);

    // TODO: create select with tabs for custom keys / standard & additional keys
    return (
      <Grid item xs={6}>
        <StyledReactSelect
          options={GetGroupedDataKeys(dataKeys, { onlyReturnFileKeys })}
          value={selectDisplayValue}
          onChange={onChangeDataKey}
          label={intl.formatMessage(messages.dataKey)}
          aria-label={intl.formatMessage(messages.dataKey)}
          getOptionLabel={(option: DataKey) => option.key}
          isOptionSelected={(option: DataKey) =>
            option.key === selectedKey.key &&
            option.predefined === selectedKey.predefined
          }
          isSearchable
          error={errorMessage}
          placeholder=""
          required
        />
      </Grid>
    );
  },
);
