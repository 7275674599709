import { Button } from '@fountain/fountain-ui-components';
import { Grid, useTheme } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFormBuilderContextState } from 'components/FormBuilder/context';
import globalMessages from 'shared/global/messages';

import { CommonQuestionsForm } from '../CommonQuestions/CommonQuestionsForm';
import { DATA_FIELD_TYPE_TO_FORM } from './constants';
import { useCreateEditQuestionFormStyles } from './styles';

const CREATE_EDIT_QUESTION_FORM_ID = 'CREATE_EDIT_QUESTION_FORM_ID';

export const CreateEditQuestionForm = () => {
  const classes = useCreateEditQuestionFormStyles();
  const { values, handleSubmit } = useFormBuilderContextState();
  const theme = useTheme();
  const AdditionFormQuestions = DATA_FIELD_TYPE_TO_FORM[values.type];

  return (
    <div className={classes.container}>
      <form
        id={CREATE_EDIT_QUESTION_FORM_ID}
        onSubmit={handleSubmit}
        className={classes.form}
      >
        <Grid container style={{ gap: theme.spacing(3) }}>
          <CommonQuestionsForm />
          {AdditionFormQuestions && <AdditionFormQuestions />}
        </Grid>
      </form>
      <div className={classes.footer}>
        <Button submit form={CREATE_EDIT_QUESTION_FORM_ID}>
          <FormattedMessage {...globalMessages.saveChanges} />
        </Button>
      </div>
    </div>
  );
};
