import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { messages } from '../messages';
import { AxAgentValues } from '../types';
import { isValidURL } from '../utils';

interface Errors {
  [key: string]: string;
}

const useValidation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<Errors>({});

  const isBotApplyOptionDisabled = (axAgentValues: AxAgentValues): boolean => {
    const { applyBotType, noOfActiveFunnelsVisibleToApplicant } = axAgentValues;

    const isMoreThanOneActiveFunnel = noOfActiveFunnelsVisibleToApplicant > 1;
    const isOnlyOneActiveFunnel = noOfActiveFunnelsVisibleToApplicant === 1;
    const isNoActiveFunnels = noOfActiveFunnelsVisibleToApplicant === 0;
    const isApplyOneBot = applyBotType === 'apply_one';

    return (
      (isApplyOneBot && (isMoreThanOneActiveFunnel || isNoActiveFunnels)) ||
      (!isApplyOneBot && (isOnlyOneActiveFunnel || isNoActiveFunnels))
    );
  };

  const validateFields = (
    axAgentValues: AxAgentValues,
    currentStep?: number,
  ): boolean => {
    const {
      chatbotName,
      enableInFountainPlatforms,
      smsEnabled,
      applyBotType,
      careerSiteUrl,
      customWebsites,
    } = axAgentValues;

    const newErrors: Errors = {};
    if (currentStep === 2 && !chatbotName.trim()) {
      newErrors.chatbotName = intl.formatMessage(messages.aiAgentNameRequired);
    }
    if (
      currentStep === 2 &&
      !enableInFountainPlatforms &&
      !smsEnabled &&
      (!customWebsites || customWebsites.length === 0)
    ) {
      newErrors.visibility = intl.formatMessage(messages.visibilityIsRequired);
    }
    if (currentStep === 2 && careerSiteUrl && !isValidURL(careerSiteUrl)) {
      newErrors.careerSiteUrl = intl.formatMessage(
        messages.invalidCareerSiteUrl,
      );
    }

    if (
      currentStep === 1 &&
      isBotApplyOptionDisabled(axAgentValues) &&
      applyBotType !== 'no_apply'
    ) {
      newErrors.applyBotType = intl.formatMessage(messages.applyBotDisabled);

      dispatch(
        addMessageAction(
          intl.formatMessage(messages.applyBotDisabled),
          'error',
        ),
      );
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateErrors = (
    name: string,
    newValue: string | boolean,
    axAgentValues: AxAgentValues,
  ): void => {
    setErrors(prevErrors => {
      const { enableInFountainPlatforms, smsEnabled, careerSiteUrl } =
        axAgentValues;
      const newErrors = { ...prevErrors };
      if (name === 'chatbotName' && newValue) {
        delete newErrors.chatbotName;
      }
      if (
        (name === 'enableInFountainPlatforms' || name === 'smsEnabled') &&
        (newValue || enableInFountainPlatforms || smsEnabled)
      ) {
        delete newErrors.visibility;
      }

      if (
        name === 'careerSiteUrl' &&
        (!careerSiteUrl || isValidURL(careerSiteUrl))
      ) {
        delete newErrors.careerSiteUrl;
      }
      return newErrors;
    });
  };

  return {
    errors,
    validateFields,
    updateErrors,
  };
};

export default useValidation;
