import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { createAgent, retrieveAgentConnection, retrieveAxAgent } from '../api';
import { initialAxAgentValues, steps } from '../constants';
import { messages } from '../messages';
import { AxAgentValues } from '../types';
import { randomAgentName } from '../utils';
import { AIAgentFunctionalities } from './AIAgentFunctionalities';
import { Footer } from './Footer';
import { KnowledgeBase } from './KnowledgeBase';
import { PersonalizeAIAgent } from './PersonalizeAIAgent';
import { useChatProgressorStyles } from './styles';
import { UnsavedChangesModal } from './UnsavedChangesModal';
import useValidation from './useValidation';
import { Widget } from './Widget';

export interface ChatbotProgressorProps {
  accessToken: string;
  accountSlug: string;
  setStartChatbotProgressor: React.Dispatch<React.SetStateAction<boolean>>;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  axAgentValues: AxAgentValues;
  knowledgeBaseOption: string;
  setKnowledgeBaseOption: React.Dispatch<React.SetStateAction<string>>;
}

export const ChatbotProgressor: VFC<ChatbotProgressorProps> = ({
  accessToken,
  accountSlug,
  setStartChatbotProgressor,
  setAxAgentValues,
  axAgentValues,
  knowledgeBaseOption,
  setKnowledgeBaseOption,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    useState<boolean>(false);
  const [isCustomWebsiteEnable, setIsCustomWebsiteEnable] = useState(
    (axAgentValues.customWebsites?.length as number) > 0,
  );
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const classes = useChatProgressorStyles({ currentStep });

  const { errors, validateFields, updateErrors } = useValidation();

  useEffect(() => {
    const createInitialAgent = async () => {
      setIsLoading(true);
      try {
        const agentResponse = await createAgent(accountSlug, randomAgentName());

        setAxAgentValues(prev => ({
          ...prev,
          axAgentId: agentResponse.agent_id,
          totalChatAgents: prev.totalChatAgents + 1,
        }));
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.agentCreationFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    const fetchAgent = async () => {
      setIsLoading(true);
      try {
        const agentData = await retrieveAxAgent(
          accessToken,
          axAgentValues.axAgentId,
        );
        const agentConnectionResponse = await retrieveAgentConnection(
          accountSlug,
          axAgentValues.axAgentId,
        );

        setAxAgentValues(prev => ({
          ...prev,
          chatbotName: agentData.name,
          emailFallback: agentData.emailFallback,
          phoneNumberFallback: agentData.phoneNumberFallback,
          websiteFallback: agentData.websiteFallback,
          enableInFountainPlatforms:
            agentConnectionResponse.agent_connection
              .enable_in_fountain_platforms,
          smsEnabled:
            agentConnectionResponse.agent_connection.sms_to_chat_agent_enabled,
          brandId: agentConnectionResponse.agent_connection.brand_external_id,
          brands: agentConnectionResponse.agent_connection.available_brands,
          atsConnectionId:
            agentConnectionResponse.agent_connection.ats_connection_id,
          status: agentData.status,
          customWebsites: agentData.allowedOrigins,
          noOfActiveFunnelsVisibleToApplicant:
            agentConnectionResponse.agent_connection
              .no_of_active_funnels_visible_to_applicant,
          applyBotType: agentData.applyBotType,
          isDuplicateSettingCannotApplyAgain:
            agentConnectionResponse.agent_connection
              .is_duplicate_setting_cannot_apply_again,
          careerSiteUrl: agentData.careerSiteUrl,
          languageCode: agentData.languageCode,
        }));

        setIsCustomWebsiteEnable(
          (agentData.allowedOrigins?.length as number) > 0,
        );
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.fetchAgentsFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (axAgentValues.axAgentId) {
      void fetchAgent();
    } else {
      void createInitialAgent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, axAgentValues.axAgentId]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentStep]);

  const resetAgent = () => {
    setStartChatbotProgressor(false);
    setAxAgentValues(initialAxAgentValues);
    setKnowledgeBaseOption('');
    setCurrentStep(1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setAxAgentValues(prev => ({
      ...prev,
      [name]: newValue,
    }));

    updateErrors(name, newValue, axAgentValues);
    setHasUnsavedChanges(true);
  };

  const handleDiscard = () => {
    setHasUnsavedChanges(false);
    setShowUnsavedChangesModal(false);
    if (nextUrl) {
      const unblock = history.block(() => undefined);
      history.push(nextUrl);
      unblock();
    } else {
      resetAgent();
    }
  };

  const handleCloseModal = () => {
    setShowUnsavedChangesModal(false);
    setNextUrl(null);
  };

  useEffect(() => {
    const unblock = history.block(location => {
      if (hasUnsavedChanges) {
        setNextUrl(location.pathname);
        setShowUnsavedChangesModal(true);
        return false; // Block navigation
      }
      return undefined; // Explicitly return undefined to allow navigation
    });

    return () => {
      unblock();
    };
  }, [hasUnsavedChanges, history]);

  const handleGoBackToAgentList = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedChangesModal(true);
    } else {
      resetAgent();
    }
  };

  const renderStep = (): JSX.Element => {
    switch (currentStep) {
      case 1:
        return (
          <PersonalizeAIAgent
            axAgentValues={axAgentValues}
            accessToken={accessToken}
            setAxAgentValues={setAxAgentValues}
            setHasUnsavedChanges={setHasUnsavedChanges}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            accountSlug={accountSlug}
            handleChange={handleChange}
            errors={errors}
          />
        );
      case 2:
        return (
          <>
            <AIAgentFunctionalities
              handleChange={handleChange}
              setAxAgentValues={setAxAgentValues}
              axAgentValues={axAgentValues}
              errors={errors}
              isLoading={isLoading}
              isCustomWebsiteEnable={isCustomWebsiteEnable}
              setIsCustomWebsiteEnable={setIsCustomWebsiteEnable}
            />
          </>
        );
      case 3:
        return (
          <>
            <KnowledgeBase
              setAxAgentValues={setAxAgentValues}
              accessToken={accessToken}
              axAgentValues={axAgentValues}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              knowledgeBaseOption={knowledgeBaseOption}
              setKnowledgeBaseOption={setKnowledgeBaseOption}
              accountSlug={accountSlug}
            />
          </>
        );
      default:
        return <div>Step not found</div>;
    }
  };

  return (
    <div className={classes.chatbotProgressor}>
      <div className={classes.progressorHeader}>
        <div className={classes.backToAgentList}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              handleGoBackToAgentList();
            }}
          >
            Back to Candidate AI Agent
          </Button>
        </div>
        <Hidden smDown>
          <div className={classes.stepProgressBar}>
            {steps.map(step => (
              <div key={step.number} className={classes.stepContainer}>
                <div
                  className={`${classes.step} ${
                    step.number <= currentStep
                      ? classes.active
                      : classes.inactive
                  }`}
                >
                  {step.number < currentStep ? '✓' : step.number}
                </div>
                <Typography variant="caption">{step.label}</Typography>
              </div>
            ))}
          </div>
        </Hidden>
      </div>
      <Grid className={classes.container}>
        <Grid className={classes.contentContainer} ref={containerRef}>
          <Grid className={classes.stepContent}>{renderStep()}</Grid>
          {!isLoading && !(currentStep === 3 && !knowledgeBaseOption) && (
            <Footer
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              axAgentValues={axAgentValues}
              accessToken={accessToken}
              setStartChatbotProgressor={setStartChatbotProgressor}
              validateFields={() => validateFields(axAgentValues, currentStep)}
              resetAgent={resetAgent}
              accountSlug={accountSlug}
              setHasUnsavedChanges={setHasUnsavedChanges}
              hasUnsavedChanges={hasUnsavedChanges}
            />
          )}
        </Grid>
        <Hidden smDown>
          <Grid className={classes.widgetContainer}>
            {!isLoading && !(currentStep === 3 && !knowledgeBaseOption) && (
              <Widget axAgentId={axAgentValues.axAgentId} />
            )}
          </Grid>
        </Hidden>
      </Grid>
      {showUnsavedChangesModal && (
        <UnsavedChangesModal
          handleClose={handleCloseModal}
          handleDiscard={handleDiscard}
          open
        />
      )}
    </div>
  );
};

export default ChatbotProgressor;
