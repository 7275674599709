import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  interviewHost: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.interviewHost`,
    defaultMessage: 'Interview Host',
  },
  interviewHostDesc: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.interviewHostDesc`,
    defaultMessage:
      'Select who will serve as the host for your interview. Fountain does not currently support mixed host types.',
  },
  moreThanOneGoEnabledSchedulerStageWithHosts: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.moreThanOneGoEnabledSchedulerStageWithHosts`,
    defaultMessage:
      'This workflow already has another Scheduler Stage that is using Go-App users as interviewers.  Only one such Scheduler Stage is allowed.',
  },
  fountainHireUsers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.fountainHireUsers`,
    defaultMessage: 'Fountain Hire users',
  },
  fountainHireUsersDesc: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.fountainHireUsersDesc`,
    defaultMessage:
      'Admins with access to Fountain Hire will serve as interview hosts',
  },
  fountainHireGoUsers: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.fountainHireGoUsers`,
    defaultMessage: 'Fountain Hire Go users',
  },
  fountainHireGoUsersDesc: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.GoEnablementSetting.fountainHireGoUsersDesc`,
    defaultMessage:
      ' Frontline managers with Fountain Hire Go accounts will serve as interview hosts',
  },
});
