import { Loader } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  CancelablePromise,
  CareerSiteService,
  CareerSiteSettingResponse,
} from 'api-clients/monolith';
import { ChatbotWidgetBrand } from 'api-clients/monolith/models/ChatbotWidgetBrand';
import React, { useEffect, useState, VFC } from 'react';

import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { Settings } from './Settings';
import { SelectedBrand } from './types';

const useStyles = makeStyles(theme => ({
  section: {
    position: 'relative',
    overflow: 'hidden',
  },
  divider: {
    margin: theme.spacing(4, 0, 1),
  },
}));

interface PublishCareerSiteProps {
  brands: ChatbotWidgetBrand[];
  defaultBrandId: string;
}

export const PublishCareerSite: VFC<PublishCareerSiteProps> = ({
  brands,
  defaultBrandId,
}) => {
  const classes = useStyles();

  const [selectedBrand, setSelectedBrand] = useState<SelectedBrand>({
    option: brands.find(
      item => item.key === defaultBrandId,
    ) as ChatbotWidgetBrand,
    brandId: defaultBrandId,
  });

  const { mutation: fetchSetting, result: settingsResponse } =
    useApiServiceMutation<
      CareerSiteSettingResponse,
      (brandId: string) => CancelablePromise<CareerSiteSettingResponse>
      // eslint-disable-next-line @typescript-eslint/unbound-method
    >(CareerSiteService.getInternalApiCareerSiteSettings);

  useEffect(() => {
    fetchSetting(selectedBrand.brandId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBrandChange = (option: ChatbotWidgetBrand) => {
    const brandId = option.key;

    setSelectedBrand({ option, brandId });
    fetchSetting(brandId);
  };

  if (settingsResponse.isLoading) return <Loader fullScreen size="2rem" />;

  if (settingsResponse.status !== 'ready') return <></>;

  return (
    <section className={classes.section}>
      <div>
        <Settings
          brands={brands}
          selectedBrand={selectedBrand}
          settingsResponse={settingsResponse.data}
          handleBrandChange={handleBrandChange}
        />
      </div>
    </section>
  );
};
