export const CHATBOT_REVIEW = 'chatbot_review';
export const AUTOMATED_RESPONSES = 'automated_responses';
export const CHATBOT_WIDGET = 'chatbot_widget';

export const FAQ_BOT = 'FAQ Bot';
export const DATA_COLLECTION_BOT = 'Data Collection Bot';
export const SCHEDULER_BOT = 'Scheduler Bot';
export const ALL = 'All';

export const CORRECT = 'correct';
export const INCORRECT = 'incorrect';
export const UNCERTAIN = 'uncertain';
export const PENDING = 'pending';

export const botSenderTypes = {
  faqBot: 'faq_bot',
  dataCollectionBot: 'data_collection_bot',
  schedulerBot: 'scheduler_bot',
  internalBot: 'internal_bot',
};

export const DEFAULT_RADIUS = '50mi';

export const RADIUS_OPTIONS = ['5mi', '10mi', '15mi', '25mi', '50mi', '100mi'];

export const DEFAULT_LOCATION_LIMIT = 5;

export const ANALYTICS = 'insights';
