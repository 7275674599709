import { Link } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Pusher from 'pusher-js/with-encryption';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  makeSelectEnvVars,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';

import useStyles from './styles';

interface NotificationContentProp {
  message: string;
  url?: string;
}

const Notification = () => {
  const { accountSlug } = useParams<{ accountSlug: string }>();
  const currentUser = useSelector(makeSelectWhoami());
  const envVars = useSelector(makeSelectEnvVars());

  const classes = useStyles();
  const [notificationContent, setNotificationContent] =
    useState<NotificationContentProp | null>(null);

  const updateNotificationContent = (data: NotificationContentProp) => {
    setNotificationContent(data);
  };
  useEffect(() => {
    const pusher = new Pusher(envVars.REACT_APP_PUSHER_APP_KEY, {
      cluster: envVars.REACT_APP_PUSHER_CLUSTER,
    });
    const channelName = `${accountSlug ?? ''};user-notification;${
      currentUser.external_id
    }`;
    const channel = pusher.subscribe(channelName);

    channel.bind('notifications', updateNotificationContent);

    return () => {
      channel.unbind();
      pusher.unsubscribe(channelName);
    };
  }, [
    accountSlug,
    currentUser,
    envVars.REACT_APP_PUSHER_APP_KEY,
    envVars.REACT_APP_PUSHER_CLUSTER,
  ]);

  const handleClose = () => {
    setNotificationContent(null);
  };

  return (
    <div data-testid="user-notification-bar">
      {notificationContent && (
        <Alert
          variant="filled"
          onClose={handleClose}
          icon={false}
          className={classes.alert}
        >
          <Typography variant="body1">
            {notificationContent?.url ? (
              <Link color="inherit" href={notificationContent.url}>
                {notificationContent?.message}
              </Link>
            ) : (
              notificationContent?.message
            )}
          </Typography>
        </Alert>
      )}
    </div>
  );
};

export default Notification;
