import { Loader } from '@fountain/fountain-ui-components';
import { makeStyles, Typography } from '@material-ui/core';
import { ChatbotWidgetService } from 'api-clients/monolith';
import { useApiService } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { BackToCompanySettings } from 'components/BackToCompanySettings';
import GlobalNav from 'components/GlobalNav';

import { messages } from './messages';
import { PublishCareerSite } from './PublishCareerSite';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'calc(100% - 12px)',
    gap: theme.spacing(10),
    maxWidth: '808px',
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  settingsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginTop: theme.spacing(2),
  },
  headerText: {
    lineHeight: '32px',
    fontWeight: 500,
  },
  title: {
    marginTop: theme.spacing(3),
  },
  inputSetting: {
    // padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(4, 0, 1),
  },
  labels: {
    marginTop: theme.spacing(2),
  },
  input: {
    width: '100%',
    padding: '8px 16px 8px 20px',
  },
}));

export const AIJobsDirectoryCareerSite = () => {
  const intl = useIntl();
  const classes = useStyles();

  const { result: chatbotWidgetBrands } = useApiService(
    useCallback(
      () => ChatbotWidgetService.getInternalApiChatbotChatbotWidgetBrands(),
      [],
    ),
  );

  const { brands, defaultBrandId } = useMemo(() => {
    if (chatbotWidgetBrands.status === 'ready') {
      const { brands, default_brand_id: defaultBrandId } =
        chatbotWidgetBrands.data;
      return {
        brands: [{ label: 'All (Default Brand)', key: 'All' }, ...brands],
        defaultBrandId,
      };
    }
    return { brands: [], defaultBrandId: null };
  }, [chatbotWidgetBrands]);

  if (chatbotWidgetBrands.isLoading) {
    return <Loader fullScreen size="2rem" />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.title)}</title>
        <meta name="description" content="AI Jobs Directory Career Site" />
      </Helmet>
      <GlobalNav />
      <div className={classes.container}>
        <div className={classes.settingsContainer}>
          <div className={classes.header}>
            <BackToCompanySettings />
            <Typography
              display="inline"
              variant="h2"
              className={classes.headerText}
            >
              <FormattedMessage {...messages.header} />
            </Typography>
          </div>

          {brands?.length > 0 && defaultBrandId && (
            <PublishCareerSite
              brands={brands}
              defaultBrandId={defaultBrandId}
            />
          )}
        </div>
      </div>
    </>
  );
};
