import {
  CustomTooltip,
  EditPencil,
  IconButton,
  Input,
  StyledReactSelect,
  styledSelectDefaultStyles,
  Trashcan,
  Warning,
} from '@fountain/fountain-ui-components';
import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import {
  MessageTemplate,
  Reason,
  ReasonSetting,
  ReminderSetting,
  TriggerSetting,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import produce from 'immer';
import { isArray } from 'lodash';
import React, { FC, useContext, useRef } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { Error } from 'components/Error';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useConditionalTooltip } from 'hooks/useConditionalTooltip/useConditionalTooltip';
import LinkIcon from 'images/TemplateLink';
import { isAmazon } from 'utils/constants';

import {
  allowDeleteByMessageType,
  ARCHIVED,
  ASSESSMENT,
  AUTOMATED,
  AVAILABILITY,
  CANCELLATION,
  CANCELLATION_V2,
  CONFIRMATION,
  CONSENT,
  DATA_COLLECTION,
  delayBySettingType,
  DOCUMENT_SIGNING,
  HIRED,
  I9FORM,
  JOB_SELECTOR,
  JOB_SWITCHER,
  LEARNING,
  messageTypesThatAllowAddingMoreMessages,
  messageTypesWithDelayCell,
  MULTI_STAGE,
  REJECTION,
  REMINDER,
  SCHEDULER,
  SCHEDULER_V2,
  SCHOOLKEEP,
  settingAndTemplateTypesByMessageType,
  stageMessageKeyByMessageType,
  stageSettingsKeyByMessageType,
  TECH_CHECK,
  TRIGGER,
  VIDEO_RECORDING,
  W4_FEDERAL_FORM,
  W4_STATE_FORM,
} from '../../constants';
import { MessageTypes } from '../../types';
import { messages } from './messages';
import { useStyles } from './styles';

const styledSelectStyles: unknown = {
  ...styledSelectDefaultStyles,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    width: 'auto',
  }),
};

type SettingTypes = ReasonSetting | TriggerSetting | ReminderSetting;

export type MessageRowProps = {
  friendlyMessageType: string;
  messageRow: SettingTypes | MessageTemplate;
  messageType: MessageTypes;
  reasons?: Reason[];
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
};

export const MessageRow: FC<MessageRowProps> = React.memo(
  ({ friendlyMessageType, messageRow, messageType, reasons, setStage }) => {
    const styles = useStyles();
    const intl = useIntl();
    const { accountSlug, funnelSlug, countryCode } = useParams<{
      accountSlug: string;
      funnelSlug: string;
      countryCode: string;
    }>();

    const { updateStageResult } = useContext(StageContext);

    const settingType = settingAndTemplateTypesByMessageType[messageType];
    const delayType = delayBySettingType[settingType];

    const {
      showContent: isDeleteMessageModalOpen,
      toggle: toggleIsDeleteMessageModalOpen,
    } = useSimpleToggle(false);

    const messageTitleRef = useRef<HTMLDivElement | null>(null);
    const { tooltipProps } = useConditionalTooltip({
      ref: messageTitleRef,
      ignoreHeight: true,
    });

    const messageTemplate: MessageTemplate =
      stageSettingsKeyByMessageType[messageType] &&
      'message_template' in messageRow
        ? messageRow.message_template
        : messageRow;

    const delayMessageByType: Record<MessageTypes, string | null> = {
      [ARCHIVED]: intl.formatMessage(messages.hourDelay),
      [AUTOMATED]: intl.formatMessage(messages.minDelay),
      [ASSESSMENT]: intl.formatMessage(messages.minDelay),
      [AVAILABILITY]: null,
      [CANCELLATION]: null,
      [CANCELLATION_V2]: null,
      [CONFIRMATION]: null,
      [CONSENT]: intl.formatMessage(messages.minDelay),
      [DATA_COLLECTION]: intl.formatMessage(messages.minDelay),
      [DOCUMENT_SIGNING]: intl.formatMessage(messages.minDelay),
      [HIRED]: intl.formatMessage(messages.minDelay),
      [I9FORM]: intl.formatMessage(messages.minDelay),
      [JOB_SELECTOR]: intl.formatMessage(messages.minDelay),
      [JOB_SWITCHER]: intl.formatMessage(messages.minDelay),
      [LEARNING]: intl.formatMessage(messages.minDelay),
      [MULTI_STAGE]: intl.formatMessage(messages.minDelay),
      [REJECTION]: intl.formatMessage(messages.hourDelay),
      [REMINDER]: intl.formatMessage(messages.minPrior),
      [SCHEDULER]: intl.formatMessage(messages.minDelay),
      [SCHEDULER_V2]: intl.formatMessage(messages.minDelay),
      [SCHOOLKEEP]: intl.formatMessage(messages.minDelay),
      [TECH_CHECK]: intl.formatMessage(messages.minDelay),
      [VIDEO_RECORDING]: intl.formatMessage(messages.minDelay),
      [TRIGGER]: intl.formatMessage(messages.dayDelay),
      [W4_FEDERAL_FORM]: intl.formatMessage(messages.minDelay),
      [W4_STATE_FORM]: intl.formatMessage(messages.minDelay),
    };

    const deliveryMessage = () => {
      if (messageTemplate.email_enabled && messageTemplate.sms_enabled) {
        return intl.formatMessage(messages.deliveryMethodBoth);
      }
      if (messageTemplate.sms_enabled) {
        return intl.formatMessage(messages.deliveryMethodSms);
      }
      return intl.formatMessage(messages.email);
    };

    const handleChangeDisabled = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          const stageDataKey =
            stageSettingsKeyByMessageType[messageType] ??
            stageMessageKeyByMessageType[messageType];

          const rowData = stageDataKey && draftStage[stageDataKey];

          if (!rowData) {
            return;
          }

          if (isArray(rowData)) {
            const rowToUpdate = (rowData as SettingTypes[]).find(
              row => row.id === messageRow.id,
            );

            if (!rowToUpdate) {
              return;
            }

            if ('disabled' in rowToUpdate) {
              rowToUpdate.disabled = !!event.target.checked;
            } else if (
              rowToUpdate.message_template &&
              rowToUpdate.id === messageRow.id
            ) {
              rowToUpdate.message_template.delivery_disabled =
                !!event.target.checked;
            }
          } else {
            rowData.delivery_disabled = !!event.target.checked;
          }
        }),
      );
    };

    const handleChangeDelay = (event: React.ChangeEvent<HTMLInputElement>) => {
      const delay = parseInt(event.target.value, 10) || 0;

      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          const stageDataKey =
            stageSettingsKeyByMessageType[messageType] ??
            stageMessageKeyByMessageType[messageType];

          const rowData = stageDataKey && draftStage[stageDataKey];

          if (!rowData) return;

          if (isArray(rowData)) {
            for (let i = 0; i < rowData.length; i += 1) {
              const rowDataToUpdate = rowData[i];

              if (rowDataToUpdate.id === messageRow.id) {
                if ('delay_hours' in messageRow) {
                  (rowDataToUpdate as ReasonSetting).delay_hours = delay;
                } else if ('minutes_to_notify' in messageRow) {
                  (rowDataToUpdate as ReminderSetting).minutes_to_notify =
                    delay;
                } else {
                  (rowDataToUpdate as TriggerSetting).idle_days = delay;
                }
                break;
              }
            }
          } else {
            rowData.delay_minutes = delay;
          }
        }),
      );
    };

    const handleClickConfirmDeleteMessage = () => {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          const stageDataKey =
            stageSettingsKeyByMessageType[messageType] ??
            stageMessageKeyByMessageType[messageType];

          const rowData = stageDataKey && draftStage[stageDataKey];

          if (!rowData) return;

          if (isArray(rowData)) {
            const idx = rowData.findIndex(
              (s: SettingTypes) => s.id === messageRow.id,
            );

            // eslint-disable-next-line no-underscore-dangle
            rowData[idx]._destroy = true;
          } else {
            // eslint-disable-next-line no-underscore-dangle
            rowData._destroy = true;
          }
        }),
      );
      toggleIsDeleteMessageModalOpen();
    };

    const handleChangeReason = (currentReason: Reason) => {
      setStage(
        produce((draftStage: WorkflowStageDetail) => {
          const { reason_settings: reasonSettings } = draftStage;
          if (!reasonSettings) return;

          for (let i = 0; i < reasonSettings.length; i += 1) {
            const rowDataToUpdate = reasonSettings[i];

            if (rowDataToUpdate.id === messageRow.id) {
              rowDataToUpdate.reason_id = currentReason.id;
              break;
            }
          }
        }),
      );
    };

    const selectedReason =
      (reasons &&
        reasons.find(rsn => {
          if (!('reason_id' in messageRow)) {
            return false;
          }
          return rsn.id === messageRow.reason_id;
        })) ||
      null;

    const generateDelayErrorMessage = () => {
      if (!updateStageResult.isError || !delayType) {
        return undefined;
      }

      const errors = updateStageResult.error?.errors;

      return errors?.[`${settingType}.${delayType}`]?.join(', ');
    };

    const delayValue =
      ('delay_hours' in messageRow && messageRow.delay_hours) ||
      ('idle_days' in messageRow && messageRow.idle_days) ||
      ('minutes_to_notify' in messageRow && messageRow.minutes_to_notify) ||
      ('delay_minutes' in messageRow && messageRow.delay_minutes) ||
      0;
    const delayErrorMessage = generateDelayErrorMessage();

    const editMessageHref = messageTypesThatAllowAddingMoreMessages[messageType]
      ? `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/stage_templates/${messageTemplate.id}/edit?funnel_id=${funnelSlug}&setting_id=${messageRow.id}`
      : `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/stage_templates/${messageTemplate.id}/edit?funnel_id=${funnelSlug}`;

    const showLinkedTemplateIndicator =
      isAmazon(accountSlug, countryCode) && messageTemplate.template_id;

    return (
      <Grid>
        {isDeleteMessageModalOpen && (
          <ConfirmationModal
            ariaLabelledBy={intl.formatMessage(messages.deleteMessage)}
            bodyContent={intl.formatMessage(
              messages.deleteMessageConfirmationText,
              { friendlyMessageType },
            )}
            cancelButtonContent={intl.formatMessage(messages.cancel)}
            confirmButtonContent={intl.formatMessage(messages.delete)}
            negative
            onCancel={toggleIsDeleteMessageModalOpen}
            onConfirm={handleClickConfirmDeleteMessage}
            title={intl.formatMessage(messages.deleteMessage)}
          />
        )}
        <Grid
          className={classNames(
            styles.messageRowContainer,
            messageType === REJECTION ? styles.rowBottomBuffer : '',
          )}
        >
          {reasons && reasons.length > 0 && (
            <Grid className={styles.rejectionReasonCell}>
              <Typography
                variant="body2"
                className={classNames(styles.cellHeader, 'required')}
              >
                {intl.formatMessage(messages.reason)}
              </Typography>
              <StyledReactSelect
                value={selectedReason}
                options={reasons}
                getOptionLabel={(option: Reason) => {
                  return option.content;
                }}
                getOptionValue={(option: Reason) => {
                  return option.id;
                }}
                onChange={handleChangeReason}
                styles={styledSelectStyles}
                autoWidth
              />
            </Grid>
          )}
          <Grid
            className={
              reasons
                ? styles.messageTitleCellWithRejection
                : styles.messageTitleCell
            }
          >
            <Grid>
              <Typography variant="body2" className={styles.cellHeader}>
                {intl.formatMessage(messages.messageTitle)}
              </Typography>
              <CustomTooltip
                dense
                title={messageTemplate.name ?? ''}
                {...tooltipProps}
              >
                <Grid className={styles.cellContent}>
                  <Typography
                    variant="h4"
                    className={styles.messageTitleContent}
                    ref={messageTitleRef}
                  >
                    {messageTemplate.name}
                  </Typography>
                </Grid>
              </CustomTooltip>
            </Grid>
          </Grid>
          <Grid className={styles.deliveryMethodCell}>
            <Grid>
              <Typography variant="body2" className={styles.cellHeader}>
                {intl.formatMessage(messages.deliveryMethod)}
              </Typography>
              <Typography variant="h4" className={styles.cellContent}>
                {deliveryMessage()}
              </Typography>
            </Grid>
          </Grid>
          {messageTypesWithDelayCell[messageType] && (
            <Grid className={styles.delayCell}>
              <Grid>
                <Typography
                  variant="body2"
                  className={classNames(styles.cellHeader, 'required')}
                >
                  {delayMessageByType[messageType]}
                </Typography>
                <Grid>
                  <Input
                    value={delayValue}
                    onChange={handleChangeDelay}
                    aria-label={intl.formatMessage(messages.delayInput)}
                    error={Boolean(delayErrorMessage)}
                  />
                  <Error error={delayErrorMessage} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className={styles.disableCell}>
            <FormControlLabel
              className={styles.disableSwitchContainer}
              label={
                <Typography
                  variant="body2"
                  className={styles.disableSwitchLabel}
                >
                  {intl.formatMessage(messages.disable)}
                </Typography>
              }
              control={
                <Switch
                  checked={
                    'disabled' in messageRow
                      ? Boolean(messageRow.disabled)
                      : Boolean(messageTemplate.delivery_disabled)
                  }
                  onChange={handleChangeDisabled}
                />
              }
            />
          </Grid>
          <Grid className={styles.actionsCell}>
            {showLinkedTemplateIndicator && (
              <Grid className={styles.linkedTemplate}>
                <LinkIcon />
                <Typography variant="caption" noWrap>
                  {intl.formatMessage(messages.linkedToTemplate)}
                </Typography>
              </Grid>
            )}
            <Grid className={styles.actionsCellContent}>
              {messageTemplate.editable ? (
                <CustomTooltip dense title={intl.formatMessage(messages.edit)}>
                  <Grid>
                    <IconButton
                      aria-label={intl.formatMessage(messages.edit)}
                      className={styles.editMessageButton}
                      href={editMessageHref}
                      isExternalLink
                    >
                      <EditPencil
                        viewBox="0 0 16 16"
                        className={styles.editMessageIcon}
                      />
                    </IconButton>
                  </Grid>
                </CustomTooltip>
              ) : (
                <CustomTooltip
                  dense
                  title={intl.formatMessage(messages.permissionDenied)}
                >
                  <Grid>
                    <IconButton
                      aria-label={intl.formatMessage(messages.edit)}
                      className={styles.editMessageButton}
                    >
                      <Warning
                        viewBox="0 0 24 24"
                        className={styles.editMessageIcon}
                      />
                    </IconButton>
                  </Grid>
                </CustomTooltip>
              )}

              {Boolean(allowDeleteByMessageType[messageType]) && (
                <CustomTooltip
                  dense
                  title={intl.formatMessage(messages.delete)}
                >
                  <Grid>
                    <IconButton
                      aria-label={intl.formatMessage(messages.deleteMessage)}
                      className={styles.deleteMessageButton}
                      onClick={toggleIsDeleteMessageModalOpen}
                    >
                      <Trashcan
                        viewBox="0 0 16 16"
                        className={styles.deleteMessageIcon}
                      />
                    </IconButton>
                  </Grid>
                </CustomTooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  },
);
