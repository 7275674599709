/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/prop-types */

import { Typography } from '@material-ui/core';
import queryString from 'query-string';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedNumber } from 'react-intl';

import ApplicantTableV2ApplicationLocaleCell from 'containers/ApplicantTableV2/components/ApplicantTableV2ApplicationLocale';
import ApplicantTableV2Cell from 'containers/ApplicantTableV2/components/ApplicantTableV2Cell';
import ApplicantTableV2CustomLabelsCell from 'containers/ApplicantTableV2/components/ApplicantTableV2CustomLabelsCell/ApplicantTableV2CustomlabelsCell';
import ApplicantTableV2DateCell from 'containers/ApplicantTableV2/components/ApplicantTableV2DateCell/ApplicantTableV2DateCell';
import ApplicantTableV2DateTimeCell from 'containers/ApplicantTableV2/components/ApplicantTableV2DateTimeCell/ApplicantTableV2DateTimeCell';
import ApplicantTableV2LinkCell from 'containers/ApplicantTableV2/components/ApplicantTableV2LinkCell/ApplicantTableV2LinkCell';
import ApplicantTableV2NameCell from 'containers/ApplicantTableV2/components/ApplicantTableV2NameCell';
import ApplicantTableV2PhonePlatformCell from 'containers/ApplicantTableV2/components/ApplicantTableV2PhonePlatformCell';
import ApplicantTableV2ProgressCell from 'containers/ApplicantTableV2/components/ApplicantTableV2ProgressCell/ApplicantTableV2ProgressCell';
import ApplicantTableV2ReferredUserCell from 'containers/ApplicantTableV2/components/ApplicantTableV2ReferredUserCell/ApplicantTableV2ReferredUserCell';
import ApplicantTableV2StatusLabelsCell from 'containers/ApplicantTableV2/components/ApplicantTableV2StatusLabelsCell/ApplicantTableV2StatusLabelsCell';
import ApplicantTableV2TypeCell from 'containers/ApplicantTableV2/components/ApplicantTableV2TypeCell';
import ApplicantTableV2UnreadMessagesCell from 'containers/ApplicantTableV2/components/ApplicantTableV2UnreadMessagesCell/ApplicantTableV2UnreadMessagesCell';
import ApplicantTableV2UserCell from 'containers/ApplicantTableV2/components/ApplicantTableV2UserCell';
import {
  COLUMN_TYPES,
  COLUMN_WIDTH_STYLES,
  STATUS,
} from 'containers/ApplicantTableV2/constants';
import { highlightStyle } from 'containers/ApplicantTableV2/styles';
import { Applicant, ExpandedColumns } from 'containers/ApplicantTableV2/types';
import { COLUMN_CATEGORIES } from 'containers/CustomizableColumnsDrawer/constants';
import referralIcon from 'images/referrals_active_sm@2x.png';
import SmsIcon from 'images/sms.svg';
import findChunksAtBeginningOfWords from 'utils/findChunksAtBeginningOfWords';

export const FETCH_JOB_DETAIL_SUCCESS =
  'app/ApplicantsV2/FETCH_JOB_DETAIL_SUCCESS';
export const FETCH_JOB_DETAIL_FAILED =
  'app/ApplicantsV2/FETCH_JOB_DETAIL_FAILED';
export const FETCH_JOB_DETAIL_INIT = 'app/ApplicantsV2/FETCH_JOB_DETAIL_INIT';
export const RESET_STAGE_APPLICANTS_COUNT =
  'app/ApplicantsV2/RESET_STAGE_APPLICANTS_COUNT';
export const REMOVE_APPLICANT_FROM_STAGE =
  'app/ApplicantsV2/REMOVE_APPLICANT_FROM_STAGE';
export const SET_SEARCH_QUERY = 'app/ApplicantsV2/SET_SEARCH_QUERY';

export const DEFAULT_STATUS_LABEL_STATE_EXPANDED = true;

interface Column {
  category: string;
  // eslint-disable-next-line camelcase
  key_type: string;
  key: string;
  title: string;
}

export const applicantValueRenderer = (
  type: string,
  value: string | boolean | Array<string>,
) => {
  if (Array.isArray(value)) {
    // expected address values:
    // ["joined address fields", "", {}]
    if (type === 'address') {
      return value.slice(0, 2).filter(Boolean).join(', ');
    }
    return value.filter(Boolean).join(', ');
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (typeof value === 'string' || value === null || value === undefined) {
    return value;
  }

  // failsafe. If we have garbage data in applicant data keys,
  // this will ensure we render a string rather than a possible object.
  // anything rendering through this will need to be properly investigate and data ingestion fixed
  return JSON.stringify(value);
};

const columnTitle = (column: Column) => {
  if (typeof column === 'object' && column !== null) {
    return column.title;
  }

  return column;
};

const columnKey = (column: Column) => {
  if (typeof column === 'object' && column !== null) {
    return column.key;
  }

  return column;
};

interface DropdownOption {
  [key: string]: {
    title: string;
    type: string;
  };
}
// figure out how to make query optional and make the key a key in COLUMN_TYPES
export interface CellType {
  title: string | JSX.Element;
  field: string;
  resizable?: boolean;
  type: string;
  style: {
    minWidth: string | number | undefined;
    maxWidth: string | number | undefined;
  };
  renderFn: ({
    applicant,
    query,
    expandedColumns,
    rowIdx,
  }: {
    applicant: Applicant;
    query: string;
    expandedColumns: ExpandedColumns;
    rowIdx: number;
  }) => JSX.Element;
  alignment?: unknown;
  disableSort?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  dropdownOptions?: DropdownOption[];
}
interface Cells {
  [key: string]: CellType;
}

export const CELLS: Cells = {
  [COLUMN_TYPES.ACCOUNT]: {
    title: 'Account',
    field: 'account',
    resizable: true,
    type: COLUMN_TYPES.ACCOUNT,
    style: COLUMN_WIDTH_STYLES.ACCOUNT,
    renderFn: ({ applicant }) => {
      return (
        <ApplicantTableV2Cell
          columnType={COLUMN_TYPES.ACCOUNT}
          key={`account-${applicant.id}`}
        >
          <Typography variant="caption" align="left" display="block">
            {applicant.account_name}
          </Typography>
        </ApplicantTableV2Cell>
      );
    },
  },
  [COLUMN_TYPES.OPENING]: {
    title: 'Job',
    field: 'funnel_title',
    resizable: true,
    type: COLUMN_TYPES.OPENING,
    style: COLUMN_WIDTH_STYLES.OPENING,
    renderFn: ({ applicant }) => {
      const host = '/';
      const path = `jobs/${applicant.opening.id}/v2/stages/${applicant.stage.id}`;
      return (
        <ApplicantTableV2LinkCell
          key={`job-${applicant.id}`}
          columnType={COLUMN_TYPES.OPENING}
          title={applicant.opening.name || applicant.opening.title}
          host={host}
          path={path}
        />
      );
    },
  },
  [COLUMN_TYPES.STAGE]: {
    title: 'Stage',
    field: 'stage_title',
    resizable: true,
    type: COLUMN_TYPES.STAGE,
    style: COLUMN_WIDTH_STYLES.STAGE,
    renderFn: ({ applicant }) => {
      const host = '/';
      const path = `jobs/${applicant.opening.id}/v2/stages/${
        applicant.stage.id
      }?${queryString.stringify({
        query: applicant.email,
      })}`;

      return (
        <ApplicantTableV2LinkCell
          key={`stage-${applicant.id}`}
          columnType={COLUMN_TYPES.STAGE}
          title={applicant.stage.name}
          host={host}
          path={path}
        />
      );
    },
  },
  [COLUMN_TYPES.PROGRESS]: {
    title: 'Progress',
    field: 'actions',
    type: COLUMN_TYPES.PROGRESS,
    style: COLUMN_WIDTH_STYLES.PROGRESS,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2ProgressCell
        key={`progress-${applicant.id}`}
        completedStages={
          applicant.progress && applicant.progress.completed_stages
        }
        totalStages={applicant.progress && applicant.progress.total_stages}
      />
    ),
  },
  [COLUMN_TYPES.USER]: {
    title: 'User',
    field: 'assigned_user',
    type: COLUMN_TYPES.USER,
    alignment: 'center',
    style: COLUMN_WIDTH_STYLES.USER,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2UserCell
        key={`applicant-user-${applicant.id}`}
        applicantId={applicant.id}
        assignedUser={applicant.assigned_user}
      />
    ),
  },
  [COLUMN_TYPES.MESSAGE_COUNT]: {
    title: (
      <img
        style={{ verticalAlign: 'middle' }}
        src={SmsIcon}
        alt="unread messages"
      />
    ),
    field: 'unread_messages_count',
    type: COLUMN_TYPES.MESSAGE_COUNT,
    alignment: 'center',
    style: COLUMN_WIDTH_STYLES.MESSAGE_COUNT,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2UnreadMessagesCell
        key={`unread-${applicant.id}`}
        unreadMessagesCount={applicant.unread_messages}
      />
    ),
  },
  [COLUMN_TYPES.NAME]: {
    title: 'Name',
    field: 'name',
    type: COLUMN_TYPES.NAME,
    style: COLUMN_WIDTH_STYLES.NAME,
    resizable: true,
    renderFn: ({ applicant, query }) => (
      <ApplicantTableV2NameCell
        key={`name-${applicant.id}`}
        applicant={applicant}
        columnType={COLUMN_TYPES.NAME}
        query={query}
      />
    ),
  },
  [COLUMN_TYPES.EMAIL]: {
    title: 'Email',
    field: 'email',
    type: COLUMN_TYPES.EMAIL,
    style: COLUMN_WIDTH_STYLES.EMAIL,
    resizable: true,
    renderFn: ({ applicant, query }) => (
      <ApplicantTableV2Cell
        columnType={COLUMN_TYPES.EMAIL}
        key={`email-${applicant.id}`}
      >
        <Highlighter
          autoEscape
          searchWords={[query]}
          textToHighlight={applicant.email ?? ''}
          highlightStyle={highlightStyle}
          title={applicant.email}
          // @ts-expect-error this will go away when converting findChunksAtBeginningOfWords
          findChunks={findChunksAtBeginningOfWords()}
        />
      </ApplicantTableV2Cell>
    ),
  },
  [COLUMN_TYPES.PHONE]: {
    title: 'Phone',
    field: 'phone_number',
    disableSort: true,
    type: COLUMN_TYPES.PHONE,
    style: COLUMN_WIDTH_STYLES.PHONE,
    renderFn: ({ applicant, query }) => (
      <ApplicantTableV2Cell
        columnType={COLUMN_TYPES.PHONE}
        key={`phone-${applicant.id}`}
      >
        <Highlighter
          autoEscape
          searchWords={[query]}
          textToHighlight={applicant.phone ?? ''}
          highlightStyle={highlightStyle}
        />
      </ApplicantTableV2Cell>
    ),
  },
  [COLUMN_TYPES.STATUS]: {
    title: STATUS,
    field: 'booked_slot',
    type: COLUMN_TYPES.STATUS,
    style: COLUMN_WIDTH_STYLES.STATUS,
    resizable: true,
    expandable: true,
    expanded: DEFAULT_STATUS_LABEL_STATE_EXPANDED,
    dropdownOptions: [
      {
        collapsed: {
          title: 'Switch to Full View',
          type: 'expand_status_cell_content',
        },
        expanded: {
          title: 'Switch to Compact View',
          type: 'expand_status_cell_content',
        },
      },
      // TODO: Backend needs to be implemented and connected to this option
      // {
      //   title: 'Sort by Pending Action',
      //   type: 'sort_by_pending_action',
      // },
    ],
    renderFn: ({ applicant }) => (
      <ApplicantTableV2StatusLabelsCell
        key={`status-${applicant.id}`}
        columnType={COLUMN_TYPES.STATUS}
        applicant={applicant}
      />
    ),
  },
  [COLUMN_TYPES.CUSTOM_LABEL]: {
    title: 'Custom Label',
    field: 'custom_label',
    type: COLUMN_TYPES.CUSTOM_LABEL,
    style: COLUMN_WIDTH_STYLES.CUSTOM_LABEL,
    resizable: true,
    expandable: true,
    expanded: false,
    dropdownOptions: [
      {
        collapsed: {
          title: 'Switch to Full View',
          type: 'expand_custom_label_cell_content',
        },
        expanded: {
          title: 'Switch to Compact View',
          type: 'expand_custom_label_cell_content',
        },
      },
    ],
    renderFn: ({ applicant, expandedColumns }) => (
      <ApplicantTableV2CustomLabelsCell
        key={`custom-label-${applicant.id}`}
        applicant={applicant}
        columnType={COLUMN_TYPES.CUSTOM_LABEL}
        expandedColumns={expandedColumns}
      />
    ),
  },
  [COLUMN_TYPES.APPLIED]: {
    title: 'Applied',
    field: 'applied_at',
    alignment: 'left',
    type: COLUMN_TYPES.APPLIED,
    style: COLUMN_WIDTH_STYLES.APPLIED,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2DateCell
        key={`applied_at-${applicant.id}`}
        columnType={COLUMN_TYPES.APPLIED}
        date={applicant.applied_at}
        textAlign="left"
      />
    ),
  },
  [COLUMN_TYPES.LANDED]: {
    title: 'Landed',
    field: 'last_transitioned_at',
    alignment: 'left',
    type: COLUMN_TYPES.LANDED,
    style: COLUMN_WIDTH_STYLES.LANDED,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2DateCell
        key={`landed_at-${applicant.id}`}
        columnType={COLUMN_TYPES.LANDED}
        date={applicant.landed_at}
        textAlign="left"
      />
    ),
  },
  [COLUMN_TYPES.FOLLOW_UP]: {
    title: 'Follow up',
    field: 'follow_up_time',
    alignment: 'left',
    type: COLUMN_TYPES.FOLLOW_UP,
    style: COLUMN_WIDTH_STYLES.FOLLOW_UP,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2DateTimeCell
        key={`follow_up-${applicant.id}`}
        columnType={COLUMN_TYPES.FOLLOW_UP}
        datetime={applicant.follow_up_time}
        textAlign="left"
      />
    ),
  },
  [COLUMN_TYPES.IDLE]: {
    title: 'Idle Since',
    field: 'idle_since',
    alignment: 'left',
    type: COLUMN_TYPES.IDLE,
    style: COLUMN_WIDTH_STYLES.IDLE,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2Cell
        columnType={COLUMN_TYPES.IDLE}
        key={`idle-${applicant.id}`}
      >
        <Typography variant="caption" align="left" display="block">
          {/* eslint-disable react/style-prop-object */}
          <FormattedNumber
            style="unit"
            unit="day"
            unitDisplay="narrow"
            value={applicant.idle_since}
          />
          {/* eslint-enable react/style-prop-object */}
        </Typography>
      </ApplicantTableV2Cell>
    ),
  },
  [COLUMN_TYPES.UTM_SOURCE]: {
    title: 'Utm Source',
    field: 'utm_source',
    alignment: 'left',
    disableSort: false,
    type: COLUMN_TYPES.UTM_SOURCE,
    style: COLUMN_WIDTH_STYLES.UTM_SOURCE,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2Cell
        columnType={COLUMN_TYPES.UTM_SOURCE}
        key={`utm_source-${applicant.id}`}
      >
        <Typography variant="caption" align="left" display="block">
          {applicant.utm_source}
        </Typography>
      </ApplicantTableV2Cell>
    ),
  },
  [COLUMN_TYPES.NOTE]: {
    title: 'Last User Note',
    field: 'note',
    alignment: 'left',
    disableSort: true,
    type: COLUMN_TYPES.NOTE,
    style: COLUMN_WIDTH_STYLES.NOTE,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2Cell
        columnType={COLUMN_TYPES.NOTE}
        key={`note-${applicant.id}`}
        useLineClamp
      >
        <Typography variant="caption" align="left" display="block">
          {applicant.note}
        </Typography>
      </ApplicantTableV2Cell>
    ),
  },
  [COLUMN_TYPES.DATE_OF_BIRTH]: {
    title: 'Date Of Birth',
    field: 'date_of_birth',
    alignment: 'left',
    type: COLUMN_TYPES.DATE_OF_BIRTH,
    style: COLUMN_WIDTH_STYLES.DATE_OF_BIRTH,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2DateCell
        key={`date_of_birth-${applicant.id}`}
        columnType={COLUMN_TYPES.DATE_OF_BIRTH}
        date={applicant.date_of_birth}
        textAlign="left"
      />
    ),
  },
  [COLUMN_TYPES.PHONE_PLATFORM]: {
    title: 'Phone Platform',
    field: 'phone_platform',
    alignment: 'center',
    disableSort: true,
    type: COLUMN_TYPES.PHONE_PLATFORM,
    style: COLUMN_WIDTH_STYLES.PHONE_PLATFORM,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2PhonePlatformCell
        key={`phone_platform-${applicant.id}`}
        phonePlatform={applicant.phone_platform}
      />
    ),
  },
  [COLUMN_TYPES.TYPE]: {
    title: 'Type',
    field: '_type',
    alignment: 'left',
    disableSort: true,
    type: COLUMN_TYPES.TYPE,
    style: COLUMN_WIDTH_STYLES.TYPE,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2TypeCell
        key={`type-${applicant.id}`}
        // eslint-disable-next-line no-underscore-dangle
        sourceType={applicant._type}
      />
    ),
  },
  [COLUMN_TYPES.APPLICATION_LOCALE]: {
    title: 'Application Language',
    field: 'application_locale',
    alignment: 'center',
    type: COLUMN_TYPES.APPLICATION_LOCALE,
    style: COLUMN_WIDTH_STYLES.APPLICATION_LOCALE,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2ApplicationLocaleCell
        key={`application_locale-${applicant.id}`}
        applicationLocale={applicant.application_locale}
      />
    ),
  },
  [COLUMN_TYPES.IDLE_MOVE_OUT_IN]: {
    title: 'Days Left In Stage',
    field: 'idle_move_out_in',
    alignment: 'center',
    type: COLUMN_TYPES.IDLE_MOVE_OUT_IN,
    style: COLUMN_WIDTH_STYLES.IDLE_MOVE_OUT_IN,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2Cell
        columnType={COLUMN_TYPES.IDLE_MOVE_OUT_IN}
        key={`idle_move_out_in-${applicant.id}`}
      >
        <Typography variant="caption" align="left" display="block">
          {/* eslint-disable react/style-prop-object */}
          {applicant.idle_move_out_in && (
            <FormattedNumber
              style="unit"
              unit="day"
              unitDisplay="narrow"
              value={applicant.idle_move_out_in}
            />
          )}
          {/* eslint-enable react/style-prop-object */}
        </Typography>
      </ApplicantTableV2Cell>
    ),
  },
  [COLUMN_TYPES.ARCHIVED_REASON]: {
    title: 'On Hold Reason',
    field: 'archived_reason',
    alignment: 'center',
    type: COLUMN_TYPES.ARCHIVED_REASON,
    style: COLUMN_WIDTH_STYLES.CUSTOM_LABEL,
    renderFn: ({ applicant }) => (
      <ApplicantTableV2Cell
        columnType={COLUMN_TYPES.ARCHIVED_REASON}
        key={`archived_reason-${applicant.id}`}
      >
        <Typography variant="caption" align="left" display="block">
          {applicant.archived_reason}
        </Typography>
      </ApplicantTableV2Cell>
    ),
  },
  [COLUMN_TYPES.REFERRED_BY]: {
    title: (
      <img
        src={referralIcon}
        alt="referred user"
        style={{ width: '100%', height: '100%' }}
      />
    ),
    field: 'referred_by',
    alignment: 'center',
    disableSort: true,
    type: COLUMN_TYPES.REFERRED_BY,
    style: COLUMN_WIDTH_STYLES.REFERRED_BY,
    renderFn: ({ applicant }) => {
      return (
        <ApplicantTableV2ReferredUserCell
          key={`unread-${applicant.id}`}
          referredUser={applicant.referred_by}
        />
      );
    },
  },
};

export const getTableCellsByColumns = (columns: Column[]) =>
  columns.map(col => {
    const key = columnKey(col);

    if (CELLS[key]) {
      return CELLS[key];
    }
    return {
      title: columnTitle(col),
      field: key,
      alignment: 'left',
      type: key,
      style: COLUMN_WIDTH_STYLES.NOTE,
      disableSort: col.category === COLUMN_CATEGORIES.default && true,
      renderFn: ({ applicant }: { applicant: Applicant }) => (
        <ApplicantTableV2Cell
          columnType={COLUMN_TYPES.NOTE}
          key={`${key}-${applicant.id}`}
          useLineClamp
        >
          <Typography variant="caption" align="left" display="block">
            {
              // @ts-expect-error this will get resolved once we correctly type out Applicant
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              applicantValueRenderer(col.key_type, applicant[key])
            }
          </Typography>
        </ApplicantTableV2Cell>
      ),
    };
  });

export const STAGE_TYPES = {
  reject: 'RejectedStage',
  onHold: 'ArchivedStage',
};
