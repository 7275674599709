/*
 *
 * CustomizableColumnsDrawer constants
 *
 */
export const DEFAULT_ACTION = 'app/CustomizableColumnsDrawer/DEFAULT_ACTION';

export const COLUMN_CATEGORIES = {
  custom: 'custom',
  default: 'default',
  standard: 'standard',
};

export const REQUIRED_COLUMN_KEYS = {
  mavColumnSettings: ['message_count', 'name', 'stage', 'opening', 'statuses'],
  applicantTableColumnSettings: ['message_count', 'name', 'statuses'],
  scheduleSessionColumnSettings: [
    'phone',
    'name',
    'attended',
    'opening',
    'stage',
  ],
};

export const EXCLUDE_COLUMN_KEYS = {
  mavColumnSettings: [],
  applicantTableColumnSettings: ['progress', 'stage', 'opening'],
  scheduleSessionColumnSettings: [],
};

export const DEFAULT_COLUMNS = {
  mavColumnSettings: [
    {
      title: 'Assigned User',
      key: 'assigned_user',
      key_type: 'assigned_user',
      category: 'default',
    },
    {
      title: 'Has Unread Messages',
      key: 'message_count',
      key_type: 'number',
      category: 'default',
    },
    {
      title: 'Name',
      key: 'name',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Progress',
      key: 'progress',
      key_type: 'progress',
      category: 'default',
    },
    {
      title: 'Stage',
      key: 'stage',
      key_type: 'stage',
      category: 'default',
    },
    {
      title: 'Opening',
      key: 'opening',
      key_type: 'opening',
      category: 'default',
    },
    {
      title: 'Idle Since',
      key: 'idle_since',
      key_type: 'da',
      category: 'default',
    },
  ],
  applicantTableColumnSettings: [
    {
      title: 'Referral',
      key: 'referred_by',
      key_type: 'referred_by',
      category: 'default',
    },
    {
      title: 'Assigned User',
      key: 'assigned_user',
      key_type: 'assigned_user',
      category: 'default',
    },
    {
      title: 'Has Unread Messages',
      key: 'message_count',
      key_type: 'number',
      category: 'default',
    },
    {
      title: 'Name',
      key: 'name',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Email',
      key: 'email',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Phone Number',
      key: 'phone',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Status',
      key: 'statuses',
      key_type: 'statuses',
      category: 'default',
    },
    {
      title: 'Custom Label',
      key: 'custom_label',
      key_type: 'custom_label',
      category: 'default',
    },
    {
      title: 'Applied Date',
      key: 'applied_at',
      key_type: 'applied_at',
      category: 'default',
    },
    {
      title: 'Landed',
      key: 'landed_at',
      key_type: 'landed_at',
      category: 'default',
    },
    {
      title: 'Idle Since',
      key: 'idle_since',
      key_type: 'da',
      category: 'default',
    },
  ],
  scheduleSessionColumnSettings: [
    {
      title: 'Applicant',
      key: 'name',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Phone Number',
      key: 'phone',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Mark attended',
      key: 'attended',
      key_type: 'boolean',
      category: 'default',
    },
    {
      title: 'Opening',
      key: 'opening',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Stage',
      key: 'stage',
      key_type: 'text',
      category: 'default',
    },
  ],
};

export const DEFAULT_AMAZON_COLUMNS = {
  applicantTableColumnSettings: [
    {
      title: 'Assigned User',
      key: 'assigned_user',
      key_type: 'assigned_user',
      category: 'default',
    },
    {
      title: 'Has Unread Messages',
      key: 'message_count',
      key_type: 'number',
      category: 'default',
    },
    {
      title: 'Name',
      key: 'name',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Email',
      key: 'email',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Phone Number',
      key: 'phone',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Status',
      key: 'statuses',
      key_type: 'statuses',
      category: 'default',
    },
    {
      title: 'Custom Label',
      key: 'custom_label',
      key_type: 'custom_label',
      category: 'default',
    },
    {
      title: 'Applied Date',
      key: 'applied_at',
      key_type: 'applied_at',
      category: 'default',
    },
    {
      title: 'Landed',
      key: 'landed_at',
      key_type: 'landed_at',
      category: 'default',
    },
    {
      title: 'Idle Since',
      key: 'idle_since',
      key_type: 'da',
      category: 'default',
    },
    {
      title: 'UTM Source',
      key: 'utm_source',
      key_type: 'utm_source',
      category: 'default',
    },
    {
      title: 'Last User Note',
      key: 'note',
      key_type: 'note',
      category: 'default',
    },
  ],
};

export const DEFAULT_AMAZON_EU_COLUMNS = {
  applicantTableColumnSettings: [
    {
      title: 'Has Unread Messages',
      key: 'message_count',
      key_type: 'number',
      category: 'default',
    },
    {
      title: 'Custom Label',
      key: 'custom_label',
      key_type: 'custom_label',
      category: 'default',
    },
    {
      title: 'Days Left In Stage',
      key: 'idle_move_out_in',
      key_type: 'date',
      category: 'default',
    },
    {
      title: 'Name',
      key: 'name',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Email',
      key: 'email',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Phone Number',
      key: 'phone',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Status',
      key: 'statuses',
      key_type: 'statuses',
      category: 'default',
    },
    {
      title: 'Applied Date',
      key: 'applied_at',
      key_type: 'applied_at',
      category: 'default',
    },
    {
      title: 'UTM Source',
      key: 'utm_source',
      key_type: 'utm_source',
      category: 'default',
    },
  ],
};

export const DEFAULT_UPS_COLUMNS = {
  scheduleSessionColumnSettings: [
    {
      title: 'Applicant',
      key: 'name',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Phone Number',
      key: 'phone',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Mark attended',
      key: 'attended',
      key_type: 'boolean',
      category: 'default',
    },
    {
      title: 'Opening',
      key: 'opening',
      key_type: 'text',
      category: 'default',
    },
    {
      title: 'Workday Id',
      key: 'workday_employee_id',
      key_type: 'text',
      category: 'custom',
    },
    {
      title: 'Stage',
      key: 'stage',
      key_type: 'text',
      category: 'default',
    },
  ],
};
