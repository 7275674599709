import { Grid, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { classNames } from 'react-extras';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { debounce } from 'utils/debounce';

import AnalyticsNavLink from './components/AnalyticsNavLink';
import ApplicantsNavMenu from './components/ApplicantsNavMenu';
import CalendarNavLink from './components/CalendarNavLink';
import GlobalNavLogo from './components/GlobalNavLogo';
import GlobalNavMobile from './components/GlobalNavMobile';
import HelpMenu from './components/HelpMenu';
import JobsNavMenu from './components/JobsNavMenu';
import Messaging from './components/Messaging';
import MoreNavMenu from './components/MoreNavMenu';
import Notification from './components/Notification';
import PosthireNavLink from './components/PosthireNavLink';
import ProfileMenu from './components/ProfileMenu';
import Search from './components/Search';
import SourcingNavLink from './components/SourcingNavLink';
import useStyles from './styles';

const GlobalNav = ({ handleQueryChange, query, funnelExternalId }) => {
  const whoami = useSelector(makeSelectWhoami());
  const {
    analytics_enabled: analyticsEnabled,
    posthire_enabled: posthireEnabled,
  } = whoami;
  const classes = useStyles();
  const desktopNav = createRef();
  const [invisible, setInvisible] = useState(true);
  const [moreDropdownVisibility, setMoreDropdownVisibility] = useState(0);
  const handleResize = useCallback(() => {
    const desktopNavWidth = desktopNav.current
      ? desktopNav.current.scrollWidth
      : 0;
    const windowWidth = window.innerWidth;
    if (moreDropdownVisibility < 3 && desktopNavWidth > windowWidth) {
      setMoreDropdownVisibility(moreDropdownVisibility + 1);
    }

    setTimeout(() => {
      setInvisible(false);
    }, 200);
  }, [desktopNav, moreDropdownVisibility]);
  const debouncedResize = useCallback(() => {
    debounce(handleResize, 200);
  }, [handleResize]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [desktopNav, handleResize]);

  useEffect(() => {
    debouncedResize();
  }, [moreDropdownVisibility, debouncedResize]);

  return (
    <>
      {/* MOBILE */}
      <Hidden mdUp>
        <GlobalNavMobile handleQueryChange={handleQueryChange} query={query} />
      </Hidden>

      {/* DESKTOP */}
      <Hidden smDown>
        <Grid
          container
          item
          direction="row"
          wrap="nowrap"
          alignItems="center"
          className={classes.container}
          ref={desktopNav}
        >
          {/* LOGO */}
          <GlobalNavLogo />

          {/* LEFT SECTION ITEMS */}
          <Grid
            className={classNames(classes.leftSection, {
              invisible,
            })}
            item
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            data-testid="navbar-main_menu"
          >
            <JobsNavMenu />

            <ApplicantsNavMenu funnelExternalId={funnelExternalId} />

            <CalendarNavLink />

            {moreDropdownVisibility < 2 && <SourcingNavLink />}

            {!moreDropdownVisibility && (
              <>
                <AnalyticsNavLink permission={analyticsEnabled} />

                <PosthireNavLink permission={posthireEnabled} />
              </>
            )}

            {moreDropdownVisibility > 0 && (
              <MoreNavMenu
                viewAnalytics={analyticsEnabled && moreDropdownVisibility > 0}
                viewPosthire={posthireEnabled && moreDropdownVisibility > 0}
                viewSourcing={moreDropdownVisibility > 1}
              />
            )}
          </Grid>

          {/* RIGHT SECTION ITEMS */}
          <Grid
            className={classNames(classes.rightSection, {
              invisible,
            })}
            item
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
          >
            <Search handleQueryChange={handleQueryChange} query={query} />
            <Messaging />
            <HelpMenu />
            <ProfileMenu />
          </Grid>
        </Grid>
        <Grid>
          <Notification />
        </Grid>
      </Hidden>
    </>
  );
};

GlobalNav.propTypes = {
  handleQueryChange: PropTypes.func,
  query: PropTypes.string,
  funnelExternalId: PropTypes.string,
};

export default GlobalNav;
