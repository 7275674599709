import { Loader } from '@fountain/fountain-ui-components';
import React, { useContext, useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  createKnowledgeBase,
  createRxAgent,
  createRxThreadAndSignature,
  updateAxAgent,
} from 'containers/ChatAgent/api';
import { AxAgentValues } from 'containers/ChatAgent/types';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { messages } from '../../../messages';
import { DisplayHeightContext } from '../../contexts/display-height-context';
import { useConversationalKnowledgeBaseStyles } from '../styles';

export interface KnowledgeBaseV2Props {
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  accessToken: string;
  axAgentValues: AxAgentValues;
  accountSlug: string;
}

export const KnowledgeBaseV2: VFC<KnowledgeBaseV2Props> = ({
  setAxAgentValues,
  accessToken,
  axAgentValues,
  accountSlug,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useConversationalKnowledgeBaseStyles();
  const chatAgentWidgetUrl = localStorage.getItem(
    'chatAgentWidgetUrl',
  ) as string;
  const displayHeight = useContext(DisplayHeightContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [iframeUrl, setIframeUrl] = useState<string>();

  useEffect(() => {
    const createAgent = async () => {
      setIsLoading(true);
      try {
        const rxAgentData = await createRxAgent(accountSlug);
        setAxAgentValues(prev => ({
          ...prev,
          rxAgentId: rxAgentData.rx_agent_id,
        }));

        let { knowledgeBaseUuid } = axAgentValues;

        if (!knowledgeBaseUuid) {
          const createdKnowledgeBase = await createKnowledgeBase(
            accessToken,
            'v2',
          );
          knowledgeBaseUuid = createdKnowledgeBase.uuid;
          setAxAgentValues(prev => ({
            ...prev,
            knowledgeBaseUuid,
          }));

          await updateAxAgent(accessToken, axAgentValues.axAgentId, {
            knowledgeBaseUuid,
          });
        }

        const rxThreadSignature = await createRxThreadAndSignature(
          accountSlug,
          axAgentValues.axAgentId,
          rxAgentData.rx_agent_id,
          knowledgeBaseUuid,
        );

        setAxAgentValues(prev => ({
          ...prev,
          rxThreadId: rxThreadSignature.thread_id,
          rxThreadSignature: rxThreadSignature.signature,
          rxThreadExpiration: rxThreadSignature.expiration,
          tenant: rxThreadSignature.tenant,
        }));
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.fetchAgentsFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (!axAgentValues.rxAgentId) void createAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIframeUrl(
      `${chatAgentWidgetUrl}/rx-agent/${String(
        axAgentValues.rxAgentId,
      )}/thread/${axAgentValues.rxThreadId}/kb/chat?signature=${
        axAgentValues.rxThreadSignature
      }&expiration=${axAgentValues.rxThreadExpiration}&tenant=${
        axAgentValues.tenant
      }`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    axAgentValues.rxThreadSignature,
    axAgentValues.rxThreadExpiration,
    chatAgentWidgetUrl,
  ]);

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <iframe
      src={iframeUrl}
      title="Conversational Knowledgebase"
      className={classes.iframe}
      width="100%"
      height="100%"
      // Calculating height by subtracting:
      // progress bar height, flex gap, padding bottom, footer buttons, more padding bottom, and an extra adjustment value
      style={{
        height: `calc(${
          displayHeight as number
        }px - 117px - 24px - 16px - 72px - 16px - 4px )`,
      }}
    />
  );
};
