import { Loader } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectAccountId } from 'containers/Auth_old/selectors';
import { AxAgentResponse, AxAgentValues } from 'containers/ChatAgent/types';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { fetchAccessToken, fetchAgentConnections, listAxAgents } from '../api';
import { initialAxAgentValues } from '../constants';
import { messages } from '../messages';
import { AgentList } from './AgentList';
import { ChatbotProgressor } from './ChatbotProgressor';
import { DisplayHeightContext } from './contexts/display-height-context';
import { SetupInstructions } from './SetupInstructions';

interface ChatbotSetupProps {
  knowledgeBaseOption: string;
  setKnowledgeBaseOption: React.Dispatch<React.SetStateAction<string>>;
  startChatbotProgressor: boolean;
  setStartChatbotProgressor: React.Dispatch<React.SetStateAction<boolean>>;
  accountSlug: string;
}

export const ChatbotSetup: VFC<ChatbotSetupProps> = ({
  knowledgeBaseOption,
  setKnowledgeBaseOption,
  startChatbotProgressor,
  setStartChatbotProgressor,
  accountSlug,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [refreshToken, setRefreshToken] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState<AxAgentResponse[]>([]);
  const [axAgentValues, setAxAgentValues] =
    useState<AxAgentValues>(initialAxAgentValues);
  const [displayHeight, setDisplayHeight] = useState<number>(0);
  const accountExternalId = useSelector(makeSelectAccountId());

  const tokenKey = `${accountExternalId}-${accountSlug}-token`;

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const resizeObserver = new ResizeObserver(() => {
        const { height } = node.getBoundingClientRect();
        setDisplayHeight(height);
      });
      resizeObserver.observe(node);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let storedToken = localStorage.getItem(tokenKey);
        const chatAgentUrl = localStorage.getItem('chatAgentBackendUrl');
        const chatAgentWidgetUrl = localStorage.getItem('chatAgentWidgetUrl');

        if (!storedToken || !chatAgentUrl || !chatAgentWidgetUrl) {
          const data = await fetchAccessToken(accountSlug);
          storedToken = data.access_token;
          localStorage.setItem(tokenKey, storedToken);
          localStorage.setItem(
            'chatAgentBackendUrl',
            data.chat_agent_connection_url,
          );
          localStorage.setItem(
            'chatAgentWidgetUrl',
            data.chat_agent_widget_url,
          );
        }

        setAccessToken(storedToken);

        const agentsData = await listAxAgents(storedToken);
        setAgents(agentsData);

        const data = await fetchAgentConnections(accountSlug);
        setAxAgentValues(prev => ({
          ...prev,
          isBrandsAvailable: data.is_brands_available,
          sampleKnowledgeBaseFileUrl: data.sample_knowledge_base_file_url,
          knowledgeBaseHelpCenterArticleUrl:
            data.knowledge_base_help_center_article_url,
          totalChatAgents: agentsData.length,
          isAllBrandSetup: data.is_all_brand_setup,
        }));
      } catch (err) {
        const error = err as Error & { status?: number };
        if (error.status === 401 || error.status === 400) {
          localStorage.removeItem(tokenKey);
          setRefreshToken(prev => !prev);
        } else {
          dispatch(
            addMessageAction(
              intl.formatMessage(messages.fetchAccessTokenFailed),
              'error',
            ),
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (!startChatbotProgressor) void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountExternalId,
    accountSlug,
    refreshToken,
    startChatbotProgressor,
    agents.length,
  ]);

  if (isLoading) return <Loader fullScreen size="2rem" />;

  if (startChatbotProgressor) {
    return (
      <Grid container ref={measuredRef}>
        <DisplayHeightContext.Provider value={displayHeight}>
          <ChatbotProgressor
            accessToken={accessToken}
            accountSlug={accountSlug}
            setStartChatbotProgressor={setStartChatbotProgressor}
            setAxAgentValues={setAxAgentValues}
            axAgentValues={axAgentValues}
            knowledgeBaseOption={knowledgeBaseOption}
            setKnowledgeBaseOption={setKnowledgeBaseOption}
          />
        </DisplayHeightContext.Provider>
      </Grid>
    );
  }

  return (
    <>
      {agents.length > 0 ? (
        <AgentList
          setStartChatbotProgressor={setStartChatbotProgressor}
          setRefreshToken={setRefreshToken}
          setAxAgentValues={setAxAgentValues}
          axAgentValues={axAgentValues}
          setAgents={setAgents}
          agents={agents}
          accountSlug={accountSlug}
          accessToken={accessToken}
        />
      ) : (
        <SetupInstructions
          onStartChatbotProgressor={() => setStartChatbotProgressor(true)}
        />
      )}
    </>
  );
};
