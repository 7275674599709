/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicantFollowUpParams } from '../models/ApplicantFollowUpParams';
import type { ApplicantFollowUpSuccessResponse } from '../models/ApplicantFollowUpSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApplicantFollowUpService {

    /**
     * create follow up
     * Creates a follow-up for the applicant
     * @param externalId Applicant's external_id
     * @param requestBody follow up params
     * @returns ApplicantFollowUpSuccessResponse success
     * @throws ApiError
     */
    public static postApiSelfServeV2ApplicantsFollowUps(
        externalId: string,
        requestBody: ApplicantFollowUpParams,
    ): CancelablePromise<ApplicantFollowUpSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/applicants/{external_id}/follow_ups',
            path: {
                'external_id': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable`,
            },
        });
    }

    /**
     * update follow up
     * Update an open follow-up for the applicant
     * @param externalId Applicant's external_id
     * @param id Follow up id
     * @param requestBody follow up params
     * @returns ApplicantFollowUpSuccessResponse success
     * @throws ApiError
     */
    public static putApiSelfServeV2ApplicantsFollowUps(
        externalId: string,
        id: string,
        requestBody: ApplicantFollowUpParams,
    ): CancelablePromise<ApplicantFollowUpSuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api_self_serve/v2/applicants/{external_id}/follow_ups/{id}',
            path: {
                'external_id': externalId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `unprocessable`,
            },
        });
    }

    /**
     * closes follow up
     * Update an open follow-up for the applicant
     * @param externalId Applicant's external_id
     * @param id Follow up id
     * @returns ApplicantFollowUpSuccessResponse success
     * @throws ApiError
     */
    public static postApiSelfServeV2ApplicantsFollowUpsMarkClosed(
        externalId: string,
        id: string,
    ): CancelablePromise<ApplicantFollowUpSuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/applicants/{external_id}/follow_ups/{id}/mark_closed',
            path: {
                'external_id': externalId,
                'id': id,
            },
            errors: {
                422: `unprocessable`,
            },
        });
    }

}