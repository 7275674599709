import { Loader } from '@fountain/fountain-ui-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { PublishChatAgent, updateAxAgent } from '../../api';
import { messages } from '../../messages';
import { AxAgentResponse } from '../../types';
import { useConfirmationModalStyles } from '../styles';

interface StatusChangeConfirmationModalProps {
  handleClose: () => void;
  agent?: AxAgentResponse;
  openConfirmDialog: boolean;
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>;
  accountSlug: string;
  accessToken: string;
}

export const StatusChangeConfirmationModal: React.FC<
  StatusChangeConfirmationModalProps
> = ({
  handleClose,
  agent,
  openConfirmDialog,
  setOpenConfirmDialog,
  setRefreshToken,
  accountSlug,
  accessToken,
}) => {
  const classes = useConfirmationModalStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const draftAgent = agent?.status === 'draft';
  const [isLoading, setIsLoading] = useState(false);

  const confirmPublishOrUnpublish = async () => {
    setIsLoading(true);
    try {
      const statusToUpdate = draftAgent ? 'active' : 'draft';

      await PublishChatAgent({
        accountSlug,
        brandId: '*',
        agentId: agent?.uuid,
        status: statusToUpdate,
      });

      await updateAxAgent(accessToken, agent?.uuid as string, {
        status: statusToUpdate,
      });

      dispatch(
        addMessageAction(
          draftAgent
            ? intl.formatMessage(messages.agentPublishSuccess)
            : intl.formatMessage(messages.agentUnPublishSuccess),
          'success',
        ),
      );
    } catch (err) {
      dispatch(
        addMessageAction(
          draftAgent
            ? intl.formatMessage(messages.agentPublishFailed)
            : intl.formatMessage(messages.agentUnPublishFailed),
          'error',
        ),
      );
    } finally {
      setIsLoading(false);
      setOpenConfirmDialog(false);
      setRefreshToken(prev => !prev);
      handleClose();
    }
  };

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <Dialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      classes={{ paperScrollPaper: classes.modalContainer }}
    >
      <DialogTitle classes={{ root: classes.modalTitle }}>
        {draftAgent ? (
          <FormattedMessage {...messages.publishAiAgent} />
        ) : (
          <FormattedMessage {...messages.unPublishAiAgent} />
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText classes={{ root: classes.modalContentText }}>
          {draftAgent ? (
            <FormattedMessage {...messages.publishAiAgentDescription} />
          ) : (
            <FormattedMessage {...messages.unPublishAiAgentDescription} />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={() => {
            setOpenConfirmDialog(false);
            handleClose();
          }}
          color="default"
          variant="outlined"
          className={classes.btnCancel}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          onClick={() => confirmPublishOrUnpublish()}
          color="secondary"
          variant="outlined"
          className={draftAgent ? classes.btnPublish : classes.btnUnpublish}
        >
          {draftAgent ? (
            <FormattedMessage {...messages.publish} />
          ) : (
            <FormattedMessage {...messages.unPublish} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
