import { Grid, Typography } from '@material-ui/core';
import {
  WorkflowSchedulerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { StageSettingCard } from 'containers/WorkflowEditor/components/StageSettingCard';

import { messages } from './messages';
import { RadioButtonsAsCards } from './RadioButtonsAsCards';
import { useStyles } from './styles';

interface GoEnablementSettingProps {
  stage: WorkflowSchedulerStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
  hasError: boolean;
}

export const GoEnablementSetting: FC<GoEnablementSettingProps> = ({
  stage,
  setStage,
  hasError = false,
}) => {
  const intl = useIntl();
  const styles = useStyles();

  return (
    <>
      <StageSettingCard
        variant="default"
        title={intl.formatMessage(messages.interviewHost)}
      >
        <Grid>
          <Typography variant="body2" className={styles.interviewHostDesc}>
            {intl.formatMessage(messages.interviewHostDesc)}
          </Typography>
          <RadioButtonsAsCards stage={stage} setStage={setStage} />
          <Error
            error={
              hasError &&
              intl.formatMessage(
                messages.moreThanOneGoEnabledSchedulerStageWithHosts,
              )
            }
            align="right"
          />
        </Grid>
      </StageSettingCard>
    </>
  );
};
