import { ReviewStageDataFieldTypeEnum } from 'api-clients/monolith/models/WorkflowReviewStage';
import { uniqueId } from 'lodash';

import {
  DataField,
  DatePickerDataField,
  TextDataField,
} from 'components/FormBuilder/schemas';

import {
  GROUPED_QUESTION_OPTIONS,
  HIRING_MANAGER_PAY_RATE_KEY,
} from './constants';

export type CreateDefaultCommonFieldArgs = {
  position: number;
  question?: string | null;
  hint?: string | null;
  key?: string | null;
};
export const createDefaultCommonField = ({
  position,
  question = '',
  hint = '',
  key = '',
}: CreateDefaultCommonFieldArgs): Omit<DataField, 'type'> & {
  _id: string;
} => ({
  _id: uniqueId(),
  question: question ?? '',
  hint: hint ?? '',
  key: key ?? '',
  position,
  required: true,
});

export const createDefaultTextField = (
  args: CreateDefaultCommonFieldArgs,
): TextDataField => ({
  type: ReviewStageDataFieldTypeEnum.textField,
  ...createDefaultCommonField(args),
});

export const createDefaultDatePickerField = (
  args: CreateDefaultCommonFieldArgs,
): DatePickerDataField => ({
  type: ReviewStageDataFieldTypeEnum.datePickerField,
  ...createDefaultCommonField(args),
});

export const createDefaultPayRateField = (
  args: CreateDefaultCommonFieldArgs,
): TextDataField => ({
  type: ReviewStageDataFieldTypeEnum.textField,
  ...createDefaultCommonField(args),
  key: HIRING_MANAGER_PAY_RATE_KEY,
  hiring_manager_standard_question: true,
});

export const getGroupedQuestionOptions = ({
  questionnaireHasHiringManagerStandardQuestion,
}: {
  questionnaireHasHiringManagerStandardQuestion: boolean;
}) =>
  // we can filter here bc there is only 1 custom question .. when we have more we'll
  // need to map then filter the options under tha custom label
  questionnaireHasHiringManagerStandardQuestion
    ? GROUPED_QUESTION_OPTIONS.filter(({ label }) => label !== 'custom')
    : GROUPED_QUESTION_OPTIONS;
