import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'app.containers.AIJobsDirectoryCareerSite.title',
    defaultMessage: 'AI Jobs Directory Career Site',
  },
  header: {
    id: 'app.containers.AIJobsDirectoryCareerSite.header',
    defaultMessage: 'AI Jobs Directory Settings',
  },
  generalSettings: {
    id: 'app.containers.AIJobsDirectoryCareerSite.generalSettings',
    defaultMessage: 'General Settings',
  },
  brandYourWidget: {
    id: 'app.containers.AIJobsDirectoryCareerSite.brandYourWidget',
    defaultMessage: 'Brand your widget',
  },
  brandYourWidgetDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.brandYourWidgetDesc',
    defaultMessage:
      'Select a brand to apply its logo and color, as well as all the chatbot widget settings configured on this page.',
  },
  additionaBrandInfo: {
    id: 'app.containers.AIJobsDirectoryCareerSite.additionaBrandInfo',
    defaultMessage:
      'To add additional brands or configure the existing ones, go to Company Settings.',
  },
  aiJobCategorization: {
    id: 'app.containers.AIJobsDirectoryCareerSite.aiJobCategorization',
    defaultMessage: 'AI Job Categorization',
  },
  categorizationSwitchLabel: {
    id: 'app.containers.AIJobsDirectoryCareerSite.categorizationSwitchLabel',
    defaultMessage: 'Categorize jobs by Job Type',
  },
  categorizationSwitchDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.categorizationSwitchDesc',
    defaultMessage:
      'Allow AI to organize Fountain positions into eight different categories by Job Department and Type (ex. Sales, Marketing, etc)',
  },
  experienceLevelSwitchLabel: {
    id: 'app.containers.AIJobsDirectoryCareerSite.experienceLevelSwitchLabel',
    defaultMessage: 'Categorize jobs by Experience Level',
  },
  experienceLevelSwitchDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.experienceLevelSwitchDesc',
    defaultMessage:
      'Allow AI to organize positions into three different work experience categories (ex.Entry-level, Mid-level and Senior-level)',
  },
  caiAgentSettings: {
    id: 'app.containers.AIJobsDirectoryCareerSite.caiAgentSettings',
    defaultMessage: 'Candidate AI Agent Settings',
  },
  faqChatPrompts: {
    id: 'app.containers.AIJobsDirectoryCareerSite.faqChatPrompts',
    defaultMessage: 'FAQ Chat Prompts',
  },
  faqChatPromptsDesc: {
    id: 'app.containers.AIJobsDirectoryCareerSite.faqChatPromptsDesc',
    defaultMessage:
      'Adds Frequently Asked Question (FAQ) chat prompts to the job directory site to help candidates quickly find answers. When applicants clicks on a question, the Candidate AI Agent chat widget will open and respond directly to that question for a seamless and engaging experience. Add up to 5 custom FAQ Chat Prompts.',
  },
  question: {
    id: 'app.containers.AIJobsDirectoryCareerSite.question',
    defaultMessage: 'Question',
  },
  settingUpdateSuccess: {
    id: 'app.containers.AIJobsDirectoryCareerSite.settingUpdateSuccess',
    defaultMessage: 'Setting updated successfully',
  },
  settingUpdateFailed: {
    id: 'app.containers.AIJobsDirectoryCareerSite.settingUpdateFailed',
    defaultMessage: 'Setting update failed',
  },
  saveSetting: {
    id: 'app.containers.AIJobsDirectoryCareerSite.saveSetting',
    defaultMessage: 'Save',
  },
  previewCareerSite: {
    id: 'app.containers.AIJobsDirectoryCareerSite.previewCareerSite',
    defaultMessage: 'Preview Career Site',
  },
  enableChatAgent: {
    id: 'app.containers.AIJobsDirectoryCareerSite.enableChatAgent',
    defaultMessage: 'An active chat agent is required',
  },
});
