import { defineMessages } from 'react-intl';

export const questionnaireDisplayMessages = defineMessages({
  editButtonText: {
    id: `app.components.FormBuilder.editButtonText`,
    defaultMessage: 'edit',
  },
  deleteButtonText: {
    id: `app.components.FormBuilder.deleteButtonText`,
    defaultMessage: 'delete',
  },
});

export const formBuilderMessages = defineMessages({
  header: {
    id: `app.components.FormBuilder.header`,
    defaultMessage: 'Questions',
  },
  addQuestionText: {
    id: `app.components.FormBuilder.addQuestionText`,
    defaultMessage: 'Add question',
  },
  editQuestionText: {
    id: `app.components.FormBuilder.editQuestionText`,
    defaultMessage: 'Edit question',
  },
  dupeKeyValidationError: {
    id: `app.components.FormBuilder.dupeKeyValidationError`,
    defaultMessage: 'Key must be unique',
  },
  emptyStateHeader: {
    id: `app.components.FormBuilder.emptyStateHeader`,
    defaultMessage: 'No questions',
  },
  emptyStateSubText: {
    id: `app.components.FormBuilder.emptyStateSubText`,
    defaultMessage:
      'Add a question to ask Hire Go users when the review a candidate',
  },
});
