import { Grid, Hidden } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import GlobalNav from 'components/GlobalNav';

import { Content } from './Content';
import { messages } from './messages';
import { Preview } from './Preview';
import { useStyles } from './styles';

export const ChatAgentUpsell: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.title)}</title>
        <meta name="description" content="Fountain AI Upsell" />
      </Helmet>
      <Grid className={classes.container}>
        <Hidden smDown>
          <GlobalNav />
        </Hidden>

        <Grid className={classes.wrapper}>
          <Content />
          <Hidden smDown>
            <Preview />
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
};
