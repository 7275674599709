import { Menu, MenuItem } from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  REACT_APP_MONOLITH_BASE_URL,
  REACT_APP_SIGN_OUT_REDIRECT_URL,
} from 'runtimeEnvVars';

import Initials from 'components/Initials';
import { signoutInitAction } from 'containers/Auth_old/actions';
import {
  makeSelectEnforceBrandBasedPermissions,
  makeSelectPolicies,
  makeSelectSignedOut,
  makeSelectWhoami,
} from 'containers/Auth_old/selectors';
import SuperAdminModal from 'containers/SuperAdminModal';

import messages from './messages';
import useStyles from './styles';

export default function ProfileMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { accountSlug } = useParams();
  const { manage_account: manageAccount } = useSelector(makeSelectPolicies());
  const {
    name: userName,
    is_super_admin: isSuperAdmin,
    wx_url: wxUrl,
  } = useSelector(makeSelectWhoami());

  const enforceBrandBasedPermissions = useSelector(
    makeSelectEnforceBrandBasedPermissions(),
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const signedOut = useSelector(makeSelectSignedOut());

  const handleUserLogOut = () => dispatch(signoutInitAction());

  useEffect(() => {
    if (signedOut) {
      window.location.replace(`${REACT_APP_SIGN_OUT_REDIRECT_URL}?app=null`);
    }
  }, [signedOut]);

  // If an account has enforceBrandBasedPermissions and the user
  // cannot manage the account, we want to default the link here to
  // be the brands page
  const companySettingsHref =
    !manageAccount && enforceBrandBasedPermissions
      ? `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/brands`
      : `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/account/edit`;

  return (
    <>
      <Menu
        id="profile-menu-trigger"
        menuWidth={244}
        triggerEl={<Initials name={userName} color="primary" />}
        showDropdownArrow={false}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -9, horizontal: 'right' }}
        MenuListProps={{ className: classes.menuList }}
      >
        <MenuItem
          isExternalLink
          href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/profile/edit`}
          className={classes.menuListItem}
        >
          <Typography variant="body2" color="textPrimary">
            <FormattedMessage {...messages.myProfile} />
          </Typography>
        </MenuItem>
        <MenuItem
          isExternalLink
          href={companySettingsHref}
          className={classes.menuListItem}
        >
          <Typography variant="body2" color="textPrimary">
            <FormattedMessage {...messages.companySettings} />
          </Typography>
        </MenuItem>
        {
          // We'll want to change this check to something
          // more appropriate when accounts using brand based
          // permissions
          // are eventually allowed to use integrations
        }
        {!enforceBrandBasedPermissions && (
          <MenuItem
            isExternalLink
            href={`${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/integrations/sourcing`}
            className={classes.menuListItem}
          >
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage {...messages.integrations} />
            </Typography>
          </MenuItem>
        )}
        {wxUrl && (
          <MenuItem
            isExternalLink
            href={wxUrl}
            className={classes.menuListItem}
          >
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage {...messages.workerExperience} />
            </Typography>
          </MenuItem>
        )}
        {isSuperAdmin && (
          <MenuItem
            onClick={() => setIsModalOpen(true)}
            className={classes.menuListItem}
          >
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage {...messages.impersonateUser} />
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleUserLogOut} className={classes.menuListItem}>
          <Typography variant="body2" color="textPrimary">
            <FormattedMessage {...messages.logOut} />
          </Typography>
        </MenuItem>
      </Menu>
      {isModalOpen && (
        <SuperAdminModal handleClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
}
