import { Grid, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  AxAgentValues,
  KnowledgeBaseResponse,
} from 'containers/ChatAgent/types';

import { messages } from '../../../messages';
import { useKnowledgeBaseStyles } from '../styles';
import { List } from './List';
import { Upload } from './Upload';

export interface KnowledgeBaseV1Props {
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  accessToken: string;
  axAgentValues: AxAgentValues;
  knowledgeBase: KnowledgeBaseResponse | undefined;
  isKnowledgeBaseUploaded: boolean;
  setIsKnowledgeBaseUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  accountSlug: string;
}

export const KnowledgeBaseV1: VFC<KnowledgeBaseV1Props> = ({
  setAxAgentValues,
  accessToken,
  axAgentValues,
  knowledgeBase,
  isKnowledgeBaseUploaded,
  setIsKnowledgeBaseUploaded,
  accountSlug,
}) => {
  const classes = useKnowledgeBaseStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Typography variant="h2">
          <FormattedMessage {...messages.uploadKnowledgeBase} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...messages.uploadKnowledgeBaseInstruction} />
        </Typography>
        <Grid className={classes.content}>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.sampleKnowledgeBaseInstruction}
              values={{
                templateFileLink: (chunks: React.ReactNode) => (
                  <a href={axAgentValues.sampleKnowledgeBaseFileUrl ?? '#'}>
                    {chunks}
                  </a>
                ),
                helpCenterLink: (chunks: React.ReactNode) => (
                  <a
                    href={
                      axAgentValues.knowledgeBaseHelpCenterArticleUrl ?? '#'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
        </Grid>
      </Grid>

      {(isKnowledgeBaseUploaded || knowledgeBase) &&
      (knowledgeBase?.uploads.length as number) > 0 ? (
        <List
          knowledgeBase={knowledgeBase}
          accessToken={accessToken}
          setIsKnowledgeBaseUploaded={setIsKnowledgeBaseUploaded}
          accountSlug={accountSlug}
        />
      ) : (
        <Upload
          setAxAgentValues={setAxAgentValues}
          accessToken={accessToken}
          setIsKnowledgeBaseUploaded={setIsKnowledgeBaseUploaded}
          axAgentValues={axAgentValues}
        />
      )}
    </Grid>
  );
};
