import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CareerSiteService,
  CareerSiteSettingResponse,
  CareerSiteSettings,
  ChatbotWidgetBrand,
} from 'api-clients/monolith';
import React, { useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

import { CaiAgentSettings } from './CaiAgentSettings';
import { Footer } from './Footer';
import { GeneralSettings } from './GeneralSettings';
import { messages } from './messages';
import { HandleSettingChange, SelectedBrand } from './types';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(4, 0),
  },
  saveButtonWrapper: {
    left: 0,
    width: '100%',
    textAlign: 'end',
    paddingTop: theme.spacing(2),
  },
  button: {
    borderRadius: '5px',
    width: 'fit-content',
  },
  labels: {
    marginTop: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export interface SettingsProps {
  brands: ChatbotWidgetBrand[];
  selectedBrand: SelectedBrand;
  handleBrandChange: (brands: ChatbotWidgetBrand) => void;
  settingsResponse: CareerSiteSettingResponse;
}

export const Settings: VFC<SettingsProps> = ({
  brands,
  selectedBrand,
  settingsResponse,
  handleBrandChange,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const styles = useStyles();

  const [settings, setSettings] = useState<CareerSiteSettings>(
    settingsResponse.settings,
  );

  const [isUpdatingSetting, setIsUpdatingSetting] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const handleSettingChange: HandleSettingChange = (key, value) => {
    setIsSaveButtonEnabled(true);
    setSettings({ ...settings, [key]: value });
  };

  const saveSettings = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsUpdatingSetting(true);
    CareerSiteService.putInternalApiCareerSiteSettings(selectedBrand.brandId, {
      career_site_setting: {
        ...settings,
      },
    })
      .then(result => {
        setIsSaveButtonEnabled(false);
        setSettings(result.settings);
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.settingUpdateSuccess),
            'success',
          ),
        );
      })
      .catch(() => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.settingUpdateFailed),
            'error',
          ),
        );
      })
      .finally(() => {
        setIsUpdatingSetting(false);
      });
  };

  return (
    <form onSubmit={event => saveSettings(event)} className={styles.form}>
      <GeneralSettings
        brands={brands}
        selectedBrandOption={selectedBrand.option}
        handleBrandChange={handleBrandChange}
        settings={settings}
        handleSettingChange={handleSettingChange}
      />

      <Divider className={styles.divider} />

      <CaiAgentSettings
        settings={settings}
        handleSettingChange={handleSettingChange}
      />

      <Footer
        isUpdaing={isUpdatingSetting}
        isSaveButtonEnabled={isSaveButtonEnabled}
        brandId={selectedBrand.brandId}
      ></Footer>
    </form>
  );
};
