import { defineMessages } from 'react-intl';

export const scope = 'app.components.globalNav.profileMenu';

export default defineMessages({
  myProfile: {
    id: `${scope}.myProfile`,
    defaultMessage: 'My Profile',
  },
  companySettings: {
    id: `${scope}.companySettings`,
    defaultMessage: 'Company Settings',
  },
  integrations: {
    id: `${scope}.integrations`,
    defaultMessage: 'Integrations',
  },
  impersonateUser: {
    id: `${scope}.impersonateUser`,
    defaultMessage: 'Impersonate Account/User',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Log Out',
  },
  workerExperience: {
    id: `${scope}.workerExperience`,
    defaultMessage: 'Worker Experience',
  },
});
