import { Grid, Typography } from '@material-ui/core';
import { EventAvailableSlot } from 'api-clients/monolith';
import React, { useMemo, useRef, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStageOnSiteAddresses } from 'hooks/useStageOnSiteAddresses';

import { messages } from '../messages';
import { useHandleLocationsInput } from './hooks/useHandleLocationsInput';
import { useInputLocationEffect } from './hooks/useInputLocationEffect';
import { useLocationResultEffect } from './hooks/useLocationResultEffect';
import { useSelectedLocationEffect } from './hooks/useSelectedLocationEffect';
import { useTitlesEffect } from './hooks/useTitlesEffect';
import { useStyles } from './styles';

export interface LocationDetailsProps {
  handleChange: (params: Partial<EventAvailableSlot>) => void;
  availableSlot: EventAvailableSlot;
}

export const LocationDetails: VFC<LocationDetailsProps> = ({
  handleChange,
  availableSlot,
}) => {
  const styles = useStyles();
  // eslint-disable-next-line camelcase
  const emptyLocation: { onsite_address: string } = useMemo(
    () => ({ onsite_address: availableSlot?.location ?? '' }),
    [availableSlot.location],
  );
  const [locations, setLocations] = useState<
    Array<{
      // eslint-disable-next-line camelcase
      onsite_address: string;
    }>
  >([]);
  const { user_external_id: user } = availableSlot;
  const [selectedLocation, setSelectedLocation] = useState<{
    // eslint-disable-next-line camelcase
    onsite_address: string;
  }>(emptyLocation);
  const [inputLocation, setInputLocation] = useState<string>(
    availableSlot?.location ?? '',
  );
  const [stageTitles, setStageTitles] = useState<string[]>(['']);
  const [isAddingLocationFromInput, setIsAddingLocationFromInput] =
    useState<boolean>(false);
  const [openingTitles, setOpeningTitles] = useState<string[]>(['']);
  const [locationGroupTitles, setLocationGroupTitles] = useState<string[]>([
    '',
  ]);
  const initialLoad = useRef(true);
  const isEdit = availableSlot.external_id !== undefined;

  const { result: locationResult } = useStageOnSiteAddresses({
    user,
    stageTitles,
    openingTitles,
    locationGroupTitles,
  });

  const handleLocationsInput = useHandleLocationsInput(
    locations,
    inputLocation,
    setInputLocation,
    selectedLocation,
    setSelectedLocation,
    locationResult,
    setIsAddingLocationFromInput,
  );

  useTitlesEffect(
    availableSlot,
    setStageTitles,
    setOpeningTitles,
    setLocationGroupTitles,
  );
  useLocationResultEffect(
    locationResult,
    setLocations,
    setInputLocation,
    setSelectedLocation,
    emptyLocation,
    inputLocation,
    isAddingLocationFromInput,
    initialLoad,
    isEdit,
  );
  useInputLocationEffect(handleChange, inputLocation);
  useSelectedLocationEffect(handleChange, locations, selectedLocation);

  return (
    <Grid className={styles.locationContainer}>
      <div>
        <Typography variant="h3">
          <FormattedMessage {...messages.location} />
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          className={styles.description}
        >
          <FormattedMessage {...messages.setLocationDescription} />
        </Typography>

        {handleLocationsInput()}
      </div>
    </Grid>
  );
};
