import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    rowChild: {
      marginTop: theme.spacing(2),
    },
    description: {
      marginTop: theme.spacing(1),
    },
    columnContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2),
      },
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
    nestedRequired: {
      '& p': {
        '&::after': {
          content: "'*'",
          color: theme.palette.common.red400,
          margin: theme.spacing(0.5),
        },
      },
    },
    required: {
      '&::after': {
        content: "'*'",
        color: theme.palette.common.red400,
        margin: theme.spacing(0.5),
      },
    },
    rowContainer: {
      marginTop: theme.spacing(3),
    },
    subLabel: {
      marginTop: theme.spacing(0.5),
    },
    tall: {
      height: '100%',
    },
    splitFormControl: {
      verticalAlign: 'middle',
      alignItems: 'start',
      justifyContent: 'center',
    },
    toggleLabel: {
      marginLeft: theme.spacing(1),
      color: theme.palette.common.black,
    },
    toggleContainer: {
      height: '100%',
      paddingTop: theme.spacing(2.25),
      display: 'inline-flex',
    },
    toggleWrapper: {
      marginLeft: theme.spacing(2),
      margin: 'auto',
    },
    gapBetween: {
      gap: theme.spacing(2),
    },
    disable: {
      '& > div': {
        background: theme.palette.common.gray100,
        '& img': {
          filter: 'opacity(0.4)',
        },
      },
    },
    switchAligned: {
      margin: theme.spacing(0.3, 2, 0, 1),
      alignSelf: 'baseline',
    },
  }),
);
