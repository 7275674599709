import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FountainGradient from './images/FountainGradient.svg';

export const useStyles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentContainer: {
    width: '60%',
    padding: theme.spacing(4, 10),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(5),
    },
  },
  previewContainer: {
    width: '40%',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    backgroundImage: `url("${FountainGradient}")`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(5),
    padding: theme.spacing(0, 5),
  },
  headerSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'flex-start',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  cardSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  card: {
    backgroundColor: '#f1f5ff',
    boxShadow: 'none',
    borderRadius: '16px',
  },
  cardHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    color: theme.palette.common.gray800,
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  cardDescription: {
    width: '100%',
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 400,
    color: theme.palette.common.gray700,
  },
  description: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 450,
    color: theme.palette.common.gray800,
  },
  back: {
    marginBottom: '-16px',
  },
  tryOutTitle: {
    color: theme.palette.common.white,
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '36px',
  },
}));
