import { EventAvailableSlot } from 'api-clients/monolith';

import { ConditionOption, ParamType } from './types';

export const processParamValues = (
  paramValues: ConditionOption[],
): Partial<EventAvailableSlot> => {
  let includesDefault = false;
  const conditions = paramValues.reduce((acc, condition) => {
    switch (condition.type) {
      case ParamType.STAGE:
        includesDefault = true;
        return {
          ...acc,
          stage_titles: condition.values.map(val => val.title),
        };
      case ParamType.OPENING:
        return {
          ...acc,
          opening_titles: condition.values.map(val => val.title),
        };
      case ParamType.LOCATION_GROUP:
        return {
          ...acc,
          location_group_titles: condition.values.map(val => val.title),
        };
      default:
        return acc;
    }
  }, {});
  return includesDefault
    ? conditions
    : { ...conditions, stage_titles: undefined };
};

const conditionMap: Record<string, keyof EventAvailableSlot> = {
  stage: 'stage_titles',
  opening: 'opening_titles',
  location_group: 'location_group_titles',
};

export function updateConditionValue(
  conditionType: string,
  handleChange: (changes: Partial<EventAvailableSlot>) => void,
  values: string[] | undefined,
): void {
  const field = conditionMap[conditionType];
  if (field) {
    handleChange({ [field]: values });
  }
}
