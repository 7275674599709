import { Button } from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectAccountSlug } from 'containers/Auth_old/selectors';

import { LoadingButton } from '../Chatbot/ChatbotWidget/Components/LoadingButton';
import { NewTabIcon } from '../Chatbot/ChatbotWidget/icons/NewTabIcon';
import { messages } from './messages';
import { getCareerSiteUrl } from './utils';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  saveButton: {
    borderRadius: '5px',
    width: '80px',
    padding: theme.spacing(1.5, 3),
  },
  button: {
    borderRadius: '5px',
    width: 'fit-content',
    padding: theme.spacing(1.5, 3),
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
}));

export interface FooterProps {
  isUpdaing: boolean;
  isSaveButtonEnabled: boolean;
  brandId: string;
}

export const Footer: React.VFC<FooterProps> = ({
  isUpdaing,
  isSaveButtonEnabled,
  brandId,
}) => {
  const styles = useStyles();
  const accountSlug = useSelector(makeSelectAccountSlug());
  const careerSiteUrl = getCareerSiteUrl(accountSlug as string, brandId);

  const openWidgetInNewTab = () => {
    window.open(careerSiteUrl, '_blank');
  };

  return (
    <div className={styles.footer}>
      <Button
        type="secondary"
        className={styles.button}
        onClick={openWidgetInNewTab}
      >
        <NewTabIcon className={styles.icon} />
        <FormattedMessage {...messages.previewCareerSite} />
      </Button>
      {!isUpdaing && (
        <Button
          type="primary"
          submit
          className={styles.saveButton}
          disabled={!isSaveButtonEnabled}
        >
          <FormattedMessage {...messages.saveSetting} />
        </Button>
      )}
      {isUpdaing && (
        <LoadingButton variant="contained" className={styles.saveButton} />
      )}
    </div>
  );
};
