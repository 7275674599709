import { Theme } from '@material-ui/core';
import { makeStyles, StyleRules } from '@material-ui/core/styles';

const generateStyles = (theme: Theme) => {
  const styles: StyleRules = {
    td: {
      minWidth: theme.customWidths.referredUserColumnWidth,
      maxWidth: theme.customWidths.referredUserColumnWidth,
    },
    referralIcon: {
      height: 24,
      width: 24,
    },
  };
  return styles;
};

const useStyles: () => Record<keyof ReturnType<typeof generateStyles>, string> =
  makeStyles(generateStyles);

export default useStyles;
