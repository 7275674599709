import { StyledReactSelect } from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { produce } from 'immer';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import { getConditionErrorMessage } from 'containers/WorkflowEditor/components/Rules/Condition/constants';
import { RulesEditDataContext } from 'containers/WorkflowEditor/contexts/rulesEditDataContext';

import { DocumentSigningRuleProps } from '../../DocumentSigningRules/types';
import { RulesProps, SharedConditionProps } from '../../types';
import { ComparatorSelect } from '../components/ComparatorSelect';
import { InputField } from '../InputField';
import { messages } from './messages';

type ScoreCardTemplateType = {
  id: number;
  name: string;
};

export const ScoreCardResult: FC<SharedConditionProps> = ({
  condition,
  ruleId,
  setRules,
  setDocumentSigningRules,
  errors,
}) => {
  const intl = useIntl();
  const { score_card_templates: scoreCardTemplates } =
    useContext(RulesEditDataContext);

  const selectedTemplate =
    scoreCardTemplates?.find(
      option => option.id === condition.ref_score_card_template_id,
    ) ?? null;

  const onChangeTemplate = (newTemplate: ScoreCardTemplateType) => {
    const rulesSetter = setRules ?? setDocumentSigningRules;

    if (rulesSetter) {
      rulesSetter(
        produce((draftRules: RulesProps | DocumentSigningRuleProps) => {
          const draftConditions =
            draftRules[ruleId]?.condition_set_attributes
              ?.conditions_attributes ?? [];
          const idx = draftConditions.findIndex(
            cond => cond.id === condition.id,
          );

          draftConditions[idx].ref_score_card_template_id = newTemplate.id;
          draftConditions[idx].extra.value = undefined;
        }),
      );
    }
  };

  const refScoreCardTemplateIdErrorMessage =
    !condition?.ref_score_card_template_id &&
    getConditionErrorMessage('ref_score_card_template_id', errors);

  return (
    <>
      <Grid item xs={6}>
        <StyledReactSelect
          options={scoreCardTemplates}
          label={intl.formatMessage(messages.template)}
          aria-label={intl.formatMessage(messages.template)}
          onChange={onChangeTemplate}
          getOptionLabel={(option: ScoreCardTemplateType) => option.name}
          getOptionValue={(option: ScoreCardTemplateType) => option.id}
          value={selectedTemplate}
          error={refScoreCardTemplateIdErrorMessage}
          placeholder=""
        />
      </Grid>
      <ComparatorSelect
        setRules={setRules}
        ruleId={ruleId}
        condition={condition}
        errors={errors}
        onlyReturnBasicComparators
      />
      <Grid item xs={6}>
        <InputField
          condition={condition}
          ruleId={ruleId}
          setRules={setRules}
          errors={errors}
          inputType="number"
          placeholder="0"
        />
      </Grid>
    </>
  );
};
