import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, VFC } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';

import {
  PublishChatAgent,
  updateAgentConnection,
  updateAtsConnection,
  updateAxAgent,
} from '../api';
import { steps } from '../constants';
import { messages } from '../messages';
import { AgentStatus, ApplyBotTypes, AxAgentValues } from '../types';
import { useFooterStyles } from './styles';

export interface FooterProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  axAgentValues: AxAgentValues;
  accessToken: string;
  setStartChatbotProgressor: React.Dispatch<React.SetStateAction<boolean>>;
  validateFields: (
    axAgentValues: AxAgentValues,
    currentStep?: number,
  ) => boolean;
  resetAgent: () => void;
  accountSlug: string;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
}

export const Footer: VFC<FooterProps> = ({
  currentStep,
  setCurrentStep,
  axAgentValues,
  accessToken,
  setStartChatbotProgressor,
  validateFields,
  resetAgent,
  accountSlug,
  setHasUnsavedChanges,
  hasUnsavedChanges,
}) => {
  const classes = useFooterStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState<AgentStatus>();
  const { external_id: userExternalId } = useSelector(makeSelectWhoami());

  const updateAgent = async () => {
    setIsLoading(true);
    try {
      await updateAgentConnection(
        accountSlug,
        axAgentValues.axAgentId,
        axAgentValues.enableInFountainPlatforms,
        axAgentValues.smsEnabled,
        axAgentValues.brandId as string,
      );
      await updateAxAgent(accessToken, axAgentValues.axAgentId, {
        name: axAgentValues.chatbotName,
        emailFallback: axAgentValues.emailFallback,
        phoneNumberFallback: axAgentValues.phoneNumberFallback,
        websiteFallback: axAgentValues.websiteFallback,
        status: axAgentValues.status,
        allowedOrigins: axAgentValues.customWebsites?.length
          ? axAgentValues.customWebsites
          : null,
        applyBotType: axAgentValues.applyBotType as ApplyBotTypes,
        lastModifiedBy: userExternalId,
        careerSiteUrl: axAgentValues.careerSiteUrl,
        languageCode: axAgentValues.languageCode,
      });

      if (hasUnsavedChanges)
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.changesSavedSuccess),
            'success',
          ),
        );

      setHasUnsavedChanges(false);
    } catch (err) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.changesSavedFailed),
          'error',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const nextStep = async () => {
    if (validateFields && !validateFields(axAgentValues, currentStep)) {
      return;
    }

    await updateAgent();
    setCurrentStep(currentStep + 1);
  };

  const prevStep = (): void => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const saveOrPublishAgent = async (status: AgentStatus) => {
    if (validateFields && !validateFields(axAgentValues, currentStep)) return;

    setLoadingAction(status);
    setIsLoading(true);
    try {
      await updateAxAgent(accessToken, axAgentValues.axAgentId, {
        status,
      });

      await PublishChatAgent({
        accountSlug,
        brandId: axAgentValues.brandId,
        enableInFountainPlatforms: axAgentValues.enableInFountainPlatforms,
        smsEnabled: axAgentValues.smsEnabled,
        agentId: axAgentValues.axAgentId,
        status,
      });

      await updateAtsConnection(
        accessToken,
        axAgentValues.atsConnectionId,
        axAgentValues.brandName,
        axAgentValues.brandId,
        accountSlug,
      );

      dispatch(
        addMessageAction(
          intl.formatMessage(
            status === 'active'
              ? messages.agentPublishSuccess
              : messages.agentSaveSuccess,
          ),
          'success',
        ),
      );
      if (setStartChatbotProgressor) {
        setStartChatbotProgressor(false);
      }
    } catch (err) {
      dispatch(
        addMessageAction(
          intl.formatMessage(
            status === 'active'
              ? messages.agentPublishFailed
              : messages.agentSaveFailed,
          ),
          'error',
        ),
      );
    } finally {
      setIsLoading(false);
      resetAgent();
    }
  };

  return (
    <div
      className={classNames(classes.navigationButtons, {
        [classes.draftNavigationButtons]: [2, 3].includes(currentStep),
      })}
    >
      {currentStep > 1 && (
        <Button
          variant="contained"
          size="small"
          type="button"
          color="default"
          onClick={prevStep}
          disabled={isLoading}
          className={classNames(classes.button, classes.previousBtn)}
        >
          <FormattedMessage {...messages.previous} />
        </Button>
      )}
      {currentStep === steps.length ? (
        <div style={{ display: 'flex', gap: '16px' }}>
          {axAgentValues.status === 'draft' && (
            <Button
              variant="contained"
              size="small"
              type="button"
              color="primary"
              onClick={() => saveOrPublishAgent('draft')}
              disabled={isLoading}
              className={classNames(classes.button, classes.draftButton)}
            >
              {loadingAction === 'draft' && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ position: 'absolute' }}
                />
              )}
              <span style={{ opacity: loadingAction === 'draft' ? 0 : 1 }}>
                <FormattedMessage {...messages.saveAsDraft} />
              </span>
            </Button>
          )}

          <Button
            variant="contained"
            size="small"
            type="button"
            color="primary"
            onClick={() => saveOrPublishAgent('active')}
            disabled={isLoading}
            className={classNames(classes.button, classes.submitButton)}
          >
            {loadingAction === 'active' && (
              <CircularProgress
                size={20}
                color="inherit"
                style={{ position: 'absolute' }}
              />
            )}
            <span style={{ opacity: loadingAction === 'active' ? 0 : 1 }}>
              <FormattedMessage {...messages.publish} />
            </span>
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          size="small"
          type="button"
          color="primary"
          onClick={nextStep}
          disabled={isLoading}
          className={classes.button}
        >
          {isLoading && (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ position: 'absolute' }}
            />
          )}
          <span style={{ opacity: isLoading ? 0 : 1 }}>
            {axAgentValues.status === 'draft' ? (
              <FormattedMessage {...messages.saveAndNext} />
            ) : (
              <FormattedMessage {...messages.publishAndNext} />
            )}
          </span>
        </Button>
      )}
    </div>
  );
};
