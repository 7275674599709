import { theme } from '@fountain/fountain-ui-components';
import produce from 'immer';

import { DELIVERY_STATUS } from 'components/SmsMessage/constants';

import {
  ADD_NEW_MESSAGE,
  ADD_NOTE,
  BULK_ACTION_INIT,
  BULK_ACTION_SUBMITTED,
  CLEAR_APPLICANT_CACHE,
  CLEAR_APPLICANT_DATA_FIELD_COLUMN,
  COLUMN_TYPES,
  DELETE_APPLICANT_DETAIL,
  DELETE_APPLICANT_ERROR,
  DELETE_APPLICANT_START,
  DELETE_APPLICANT_SUCCESS,
  DELETE_NOTE,
  FETCH_APPLICANT_DETAILS,
  FETCH_APPLICANT_DETAILS_DONE,
  FOLLOW_UP_TIME_WIDTH,
  IDLE_MOVE_OUT_IN_COLUMN_WIDTH,
  REMOVE_APPLICANT_FROM_STAGE,
  REMOVE_APPLICANT_FROM_STAGE_ON_CLOSE,
  REMOVE_APPLICANT_MESSAGE,
  REMOVE_APPLICANT_TEMP_MESSAGE,
  REMOVE_SELECTED_APPLICANT_ID,
  RESET_APPLICANT_DATA,
  RESET_APPLICANT_UNREAD_MESSAGES,
  RESET_SELECTED_APPLICANT_IDS,
  RESIZE_TABLE_COLUMN,
  SELECT_ALL,
  SEND_WHATSAPP_CONVERSATION_REQUEST,
  SET_APPLICANT_BLOB,
  SET_APPLICANT_BLOBS,
  SET_APPLICANT_DETAIL,
  SET_APPLICANT_EXTRA_DETAIL,
  SET_APPLICANT_MESSAGES,
  SET_APPLICANT_MESSAGING_THREAD,
  SET_APPLICANT_NOTES,
  SET_APPLICANTS,
  SET_APPLICANTS_ERROR,
  SET_APPLICANTS_SUCCESS,
  SET_BOT_SUGGESTION_SENT,
  SET_IS_OVERLAY_VISIBILE_FALSE,
  SET_IS_OVERLAY_VISIBILE_TRUE,
  SET_SELECT_BY_COUNT,
  SET_SELECTED_APPLICANT_DETAIL,
  SET_SELECTED_APPLICANT_IDS,
  SET_SELECTED_FILTER_STAGE_IDS_WITH_FUNNELS,
  UPDATE_APPLICANT_DATA_FIELD_COLUMN,
  UPDATE_APPLICANT_DETAIL,
  UPDATE_MESSAGE_DATA_KEY_BY_MESSAGE_ID,
  UPDATE_MESSAGE_MEDIA_DELETED_BY_MESSAGE_ID,
  UPDATE_NOTE,
  UPDATE_PROFILE_APPLICANT_STATUSES,
  UPDATE_TABLE_APPLICANT_STATUSES,
  UPDATE_WHATSAPP_MESSAGE,
  USER_COLUMN_WIDTH,
} from './constants';
import { BulkSelect } from './types';

export const initialState = {
  applicants: {},
  isFetchingApplicants: false,
  isDeletingApplicants: false,
  isLoading: false,
  selectedApplicantIds: [], // 'all' | applicant_id[] | []
  bulkSelect: BulkSelect.NONE,
  totalRows: 0,
  selectedFilterStageIdsWithFunnel: [],
  selectedFilterJobIdsCount: 0,
  shouldShowOverlay: false,
  tableColumnWidths: {
    [COLUMN_TYPES.NAME]: theme.customWidths.nameColumnWidth,
    [COLUMN_TYPES.USER]: USER_COLUMN_WIDTH,
    [COLUMN_TYPES.STAGE]: theme.customWidths.stageColumnWidth,
    [COLUMN_TYPES.EMAIL]: theme.customWidths.emailColumnWidth,
    [COLUMN_TYPES.PHONE]: theme.customWidths.phoneColumnWidth,
    [COLUMN_TYPES.OPENING]: theme.customWidths.openingColumnWidth,
    [COLUMN_TYPES.ACCOUNT]: theme.customWidths.openingColumnWidth,
    [COLUMN_TYPES.STATUS]: theme.customWidths.statusColumnWidth,
    [COLUMN_TYPES.CUSTOM_LABEL]: theme.customWidths.customLabelColumnWidth,
    [COLUMN_TYPES.APPLIED]: theme.customWidths.appliedColumnWidth,
    [COLUMN_TYPES.LANDED]: theme.customWidths.landedColumnWidth,
    [COLUMN_TYPES.IDLE]: theme.customWidths.idleColumnWidth,
    [COLUMN_TYPES.UTM_SOURCE]: theme.customWidths.utmSourceColumnWidth,
    [COLUMN_TYPES.NOTE]: theme.customWidths.noteColumnWidth,
    [COLUMN_TYPES.ARCHIVED_REASON]:
      theme.customWidths.archivedReasonColumnWidth,
    [COLUMN_TYPES.IDLE_MOVE_OUT_IN]: IDLE_MOVE_OUT_IN_COLUMN_WIDTH,
    [COLUMN_TYPES.FOLLOW_UP]: FOLLOW_UP_TIME_WIDTH,
  },
  selectedApplicant: {},
  bulkActionSubmitting: false,
};

/*
  Actions for this reducer are dispatched from multiple containers
  ApplicantTableV2, ApplicantDrawerPopup, ApplicantDrawerStatus, ApplicantDrawerNotes.
  This is for merging applicants data into single object as follows
  {
    applicantId: {
      notes: [],         // ApplicantDrawerNotes
      details: {...},       // ApplicantDrawerPopup
      messages: [],         // ApplicantDrawerMessages
      ...all other details  // ApplicantTableV2
    },
    applicantId2: {
      ...
    }
  }
  This way, all applicants data are always in sync, preventing extra queries.
*/
const applicantTableV2Reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ADD_NEW_MESSAGE:
        // eslint-disable-next-line no-case-declarations
        const indexOfExistingMessageInSelectApplicant =
          draft.selectedApplicant.messages.findIndex(
            message => message.id === action.message.id,
          );
        if (indexOfExistingMessageInSelectApplicant > -1) {
          draft.selectedApplicant.messages[
            indexOfExistingMessageInSelectApplicant
          ] = action.message;
        } else {
          draft.selectedApplicant.messages.push(action.message);
        }
        break;
      case SEND_WHATSAPP_CONVERSATION_REQUEST:
        draft.selectedApplicant.pending_conversation_request = true;
        break;
      case UPDATE_WHATSAPP_MESSAGE:
        if (action.message.sender_type === 'applicant') {
          draft.selectedApplicant.last_whats_app_received_at =
            action.message.created_at;
        } else {
          if (action.message.status !== DELIVERY_STATUS.SENDING) {
            draft.selectedApplicant.pending_conversation_request = false;
          }
          if (
            action.message.status === DELIVERY_STATUS.DELIVERED ||
            action.message.status === DELIVERY_STATUS.READ ||
            action.message.status === DELIVERY_STATUS.SENT
          ) {
            draft.selectedApplicant.last_conversation_request_sent_at =
              action.message.applicant_last_conversation_request_sent_at;
          }
        }
        break;
      case ADD_NOTE: {
        draft.selectedApplicant.notes = [
          action.newNote,
          ...state.selectedApplicant.notes,
        ];

        const applicant = draft.applicants[action.applicantId];
        if (!applicant) break;
        applicant.note = action.newNote.content;
        break;
      }
      case DELETE_APPLICANT_START:
        draft.isDeletingApplicants = true;
        break;
      case DELETE_APPLICANT_SUCCESS:
        draft.isDeletingApplicants = false;
        break;
      case DELETE_APPLICANT_ERROR:
        draft.isDeletingApplicants = false;
        break;
      case DELETE_NOTE: {
        draft.selectedApplicant.notes = draft.selectedApplicant.notes.filter(
          note => note.id !== action.noteId,
        );

        const applicant = draft.applicants[action.applicantId];

        if (!applicant) break;

        const applicantNotes = draft.selectedApplicant.notes;
        const latestNote = applicantNotes[0];

        applicant.note =
          latestNote && latestNote.content ? latestNote.content : '';
        break;
      }
      case SET_APPLICANTS: {
        draft.isFetchingApplicants = true;
        break;
      }
      case SET_APPLICANTS_SUCCESS:
        draft.totalRows = action.count;
        // we use reduce here to make applicants an object out of array
        // eslint-disable-next-line no-case-declarations
        const applicantsObj = action.applicants.reduce((acc, current) => {
          const applicantId = current.id;
          /* eslint-disable-next-line no-param-reassign */
          acc[applicantId] = {
            ...draft.applicants[applicantId], // data from stored applicant
            // data from fetched applicant.
            // if same key exists, fetched should override existing data.
            // ex) stage info of stage change)
            ...current,
          };
          return acc;
        }, {});
        draft.applicants = applicantsObj;
        draft.isFetchingApplicants = false;
        break;
      case SET_APPLICANTS_ERROR: {
        draft.totalRows = 0;
        draft.applicants = [];
        draft.isFetchingApplicants = false;
        break;
      }
      case UPDATE_APPLICANT_DETAIL: {
        draft.selectedApplicant.details[action.dataType] = action.data;
        break;
      }
      case UPDATE_APPLICANT_DATA_FIELD_COLUMN: {
        const applicant = draft.applicants[action.applicantId];
        const { key, value } = action.dataField;
        applicant[key] = value;
        draft.applicants[action.applicantId] = applicant;
        break;
      }
      case CLEAR_APPLICANT_DATA_FIELD_COLUMN: {
        const applicant = draft.applicants[action.applicantId];
        const { key } = action;
        applicant[key] = '';
        draft.applicants[action.applicantId] = applicant;
        break;
      }
      case DELETE_APPLICANT_DETAIL: {
        const applicant = draft.applicants[action.applicantId];
        const detailToDelete = applicant.details[action.dataType].find(
          detail => detail.key === action.key,
        );
        detailToDelete.value = '';
        draft.applicants[action.applicantId] = applicant;
        break;
      }
      case UPDATE_TABLE_APPLICANT_STATUSES: {
        const applicant = draft.applicants[action.applicantId];
        if (!applicant) {
          break;
        }
        applicant.status = action.statuses;
        draft.applicants[action.applicantId] = applicant;
        break;
      }
      case UPDATE_PROFILE_APPLICANT_STATUSES: {
        const applicant = draft.selectedApplicant;
        if (!applicant) {
          break;
        }
        applicant.status = action.statuses;
        draft.selectedApplicant = applicant;
        break;
      }
      case SET_APPLICANT_BLOB: {
        if (draft.applicants[action.applicantId] !== undefined) {
          draft.applicants[action.applicantId].blobs = draft.applicants[
            action.applicantId
          ].blobs.map(blob => {
            if (blob.id !== action.blob.id) return blob;
            return action.blob;
          });
        }
        draft.selectedApplicant.blobs = draft.selectedApplicant.blobs.map(
          blob => {
            if (blob.id !== action.blob.id) return blob;
            return action.blob;
          },
        );
        break;
      }
      case SET_APPLICANT_BLOBS:
        if (draft.applicants[action.applicantId] !== undefined) {
          draft.applicants[action.applicantId].blobs = action.blobs;
        }
        draft.selectedApplicant.blobs = action.blobs;
        break;
      case SET_APPLICANT_EXTRA_DETAIL:
        {
          const job = action.applicantData.job || action.applicantData.opening;
          if (draft.applicants[action.applicantId] !== undefined) {
            draft.applicants[action.applicantId] = {
              ...draft.applicants[action.applicantId],
              // should be this order, so that detailed info overrides
              // default info if same key exists
              ...action.applicantData,
              // TODO: When fetching an applicant we get the attribute job
              // but when fetching multiple applicants, we get opening. Eventually
              // need to update this to use just job or opening.
              job,
              opening: job,
            };
          }
        }
        break;
      case SET_SELECTED_APPLICANT_DETAIL:
        draft.selectedApplicant = action.applicantData;
        break;
      case SET_IS_OVERLAY_VISIBILE_TRUE:
        draft.shouldShowOverlay = true;
        break;
      case SET_IS_OVERLAY_VISIBILE_FALSE:
        draft.shouldShowOverlay = false;
        break;
      case FETCH_APPLICANT_DETAILS:
        draft.isLoading = true;
        break;
      case FETCH_APPLICANT_DETAILS_DONE:
        draft.isLoading = false;
        break;
      case SET_APPLICANT_NOTES:
        draft.selectedApplicant.notes = action.notes;
        break;
      case SET_APPLICANT_MESSAGES:
        draft.selectedApplicant.messages = action.messages;
        break;
      case SET_BOT_SUGGESTION_SENT:
        draft.selectedApplicant.messages.forEach((message, index) => {
          if (message.id === action.applicantMessageId) {
            draft.selectedApplicant.messages[
              index
            ].is_bot_suggestion_sent = true;
          }
        });
        break;
      case SET_APPLICANT_MESSAGING_THREAD:
        draft.selectedApplicant.messaging_thread = action.messagingThread;
        break;
      case RESET_APPLICANT_UNREAD_MESSAGES:
        draft.applicants[action.applicantId].unread_messages = 0;
        break;
      case SET_SELECTED_APPLICANT_IDS:
        draft.selectedApplicantIds = action.selectedApplicantIds;
        draft.selectByCount = undefined;
        draft.bulkSelect =
          (action.selectedApplicantIds === SELECT_ALL && BulkSelect.ALL) ||
          (action.selectedApplicantIds.length > 1 && BulkSelect.SOME) ||
          BulkSelect.NONE;
        break;
      case RESET_SELECTED_APPLICANT_IDS:
        draft.selectedApplicantIds = initialState.selectedApplicantIds;
        draft.bulkSelect = BulkSelect.NONE;
        break;
      case REMOVE_SELECTED_APPLICANT_ID:
        if (!Array.isArray(draft.selectedApplicantIds)) {
          return;
        }
        draft.selectedApplicantIds = draft.selectedApplicantIds.filter(
          id => id !== action.selectedApplicantId,
        );
        break;
      case SET_SELECT_BY_COUNT:
        draft.selectByCount = action.selectByCount;
        draft.selectedApplicantIds = SELECT_ALL; // HI-862  ideally this is set to [], but its a bit complex atm
        draft.bulkSelect = BulkSelect.COUNT;
        break;
      case UPDATE_NOTE: {
        const updatedNoteId = action.updatedNote.id;
        draft.selectedApplicant.notes.forEach((note, index) => {
          if (note.id === updatedNoteId) {
            draft.selectedApplicant.notes[index] = action.updatedNote;
          }
        });

        const applicant = draft.applicants[action.applicantId];

        if (!applicant) break;

        const applicantNotes = draft.selectedApplicant.notes;
        const latestNote = applicantNotes[0];

        if (latestNote.id === updatedNoteId) {
          applicant.note = action.updatedNote.content;
        }
        break;
      }
      case REMOVE_APPLICANT_FROM_STAGE:
        action.applicantIds.forEach(
          applicantId => delete draft.applicants[applicantId],
        );
        draft.totalRows -= action.applicantIds.length;
        break;
      case REMOVE_APPLICANT_FROM_STAGE_ON_CLOSE:
        action.applicantIds.forEach(
          applicantId => delete draft.applicants[applicantId],
        );
        draft.totalRows -= action.applicantIds.length;
        break;
      case REMOVE_APPLICANT_MESSAGE:
        draft.selectedApplicant.messages =
          draft.selectedApplicant.messages.filter(
            msg => msg.id !== action.messageId,
          );
        break;
      case REMOVE_APPLICANT_TEMP_MESSAGE: {
        const { messages } = draft.selectedApplicant;
        const newMessages = messages.filter(msg => !msg.temp);
        draft.selectedApplicant.messages = newMessages;
        break;
      }
      case RESET_APPLICANT_DATA: {
        const tableApplicant = draft.applicants[action.applicant.id];

        if (tableApplicant) {
          tableApplicant.location = action.applicant.location;
          tableApplicant.stage =
            action.applicant.toStage || action.applicant.stage;
          tableApplicant.opening = {
            id: action.applicant.job.id,
            name: action.applicant.job.title,
            slug: action.applicant.job.slug,
          };
          tableApplicant.brand = action.applicant.brand;
        }
        draft.selectedApplicant = {
          ...draft.selectedApplicant,
          ...action.applicant,
          ...{ status: draft.selectedApplicant.status },
        };
        break;
      }
      case SET_SELECTED_FILTER_STAGE_IDS_WITH_FUNNELS:
        draft.selectedFilterStageIdsWithFunnel =
          action.selectedFilterStageIdsWithFunnel;
        break;
      case RESIZE_TABLE_COLUMN:
        draft.tableColumnWidths[action.columnType] = action.width;
        break;
      case BULK_ACTION_INIT:
        draft.bulkActionSubmitting = true;
        break;
      case BULK_ACTION_SUBMITTED:
        draft.bulkActionSubmitting = false;
        break;
      case SET_APPLICANT_DETAIL:
        if (!draft.details) {
          draft.details = {};
        }
        draft.details[action.applicant.info.id] = action.applicant;
        break;
      case CLEAR_APPLICANT_CACHE:
        switch (typeof action.applicantIds) {
          case 'object':
            action.applicantIds.forEach(id => delete draft.details[id]);
            break;
          case 'number':
            delete draft.details[action.applicantIds];
            break;
          default:
            delete draft.details;
            break;
        }
        break;
      case UPDATE_MESSAGE_DATA_KEY_BY_MESSAGE_ID:
        if (draft.selectedApplicant && action.messageId && action.dataKey) {
          draft.selectedApplicant.messages.forEach(message => {
            if (message.id === action.messageId) {
              // eslint-disable-next-line no-param-reassign
              message.data_key = action.dataKey;
            }
          });
        }
        break;
      case UPDATE_MESSAGE_MEDIA_DELETED_BY_MESSAGE_ID:
        if (draft.selectedApplicant && action.messageId) {
          draft.selectedApplicant.messages.forEach(message => {
            if (message.id === action.messageId) {
              // eslint-disable-next-line no-param-reassign
              message.media_deleted = true;
            }
          });
        }
        break;
      // no default
    }
  });

export default applicantTableV2Reducer;
