/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpeningsService {

    /**
     * Request download of Opening CSV
     * Enqueues a job and returns status code only
     * @returns void
     * @throws ApiError
     */
    public static getInternalApiOpeningsCsvs(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/openings/csvs',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Upload CSV to update openings
     * Updates openings via uploaded CSV.
     * @param requestBody csv
     * @returns void
     * @throws ApiError
     */
    public static postInternalApiOpeningsCsvs(
        requestBody?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/openings/csvs',
            body: requestBody,
            mediaType: 'text/csv',
            errors: {
                400: `Bad File`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Reassign Opening to Different Workflow (Alpha)
     * Takes a stage mapping array to move applicants from one stage to another.  The stage mapping array is an array of hashes with the keys "source" and "destination".  The "source" key is the name of the stage in the source workflow, and the "destination" key is the name of the stage in the destination workflow. This feature is in Alpha, meaning that explicit flags must be set for this endpoint to behave as expected. If you are interested in trying this API, please reach out to your account representative for more information.
     * @param requestBody
     * @returns any {"async":false}
     * @throws ApiError
     */
    public static postInternalApiOpeningsWorkflowReassignment(
        requestBody: {
            destination_workflow_id: string;
            destination_workflow_title: string | null;
            run_landing_actions: boolean;
            source_opening_id: string;
            stage_mappings: Array<{
                source_stage_id?: string;
                destination_stage_id?: string;
            }> | null;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/openings/workflow_reassignment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Targeted Openings Count for a list of Locations and Positions
     * Get count of targeted funnels for a list of locations and positions
     * @param xAccessToken
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static postInternalApiWxFunnelsTargetedCount(
        xAccessToken: string,
        requestBody?: {
            location_ids: Array<string>;
            position_ids: Array<string>;
        },
    ): CancelablePromise<{
        /**
         * The count of targeted funnels for a list of locations and positions
         */
        targeted_count?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/internal_api/wx/funnels/targeted_count',
            headers: {
                'X-ACCESS-TOKEN': xAccessToken,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}