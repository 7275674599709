import { TableCell } from '@material-ui/core';
import React, { FC, memo } from 'react';

import referralIcon from 'images/referrals_active_sm@2x.png';

import useStyles from './styles';

export interface ApplicantTableV2ReferredUserCellProps {
  referredUser: string;
}

export const ApplicantTableV2ReferredUserCell: FC<
  ApplicantTableV2ReferredUserCellProps
> = ({ referredUser }) => {
  const styles = useStyles();

  return (
    <TableCell role="cell" className={styles.td}>
      {referredUser ? (
        <img
          src={referralIcon}
          alt="referred user"
          className={styles.referralIcon}
        />
      ) : (
        <div className={styles.referralIcon}></div>
      )}
    </TableCell>
  );
};

export default memo(ApplicantTableV2ReferredUserCell);
