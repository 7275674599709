import {
  WorkflowSchedulerStage,
  WorkflowStageDetail,
} from 'api-clients/monolith';
import produce from 'immer';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { messages } from '../messages';
import { useStyles } from './styles';

interface RadioButtonsAsCardsProps {
  stage: WorkflowSchedulerStage;
  setStage: React.Dispatch<React.SetStateAction<WorkflowStageDetail>>;
}

export const RadioButtonsAsCards: FC<RadioButtonsAsCardsProps> = ({
  stage,
  setStage,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const usingGoInterviewHosts =
    stage.additional_info.use_go_hosts_for_interviewers;

  const setUseGoHostsForInterviews = (value: boolean) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.additional_info.use_go_hosts_for_interviewers = value;

        // if the user selects to use Go hosts, enable stage for Go too.
        if (value) {
          draftStage.go_enabled = true;
        }
      }),
    );
  };

  return (
    <div className={classes.optionsContainer}>
      <label
        htmlFor="scheduler_stage_not_use_go_hosts_for_interviewers"
        className={classes.optionDiv}
      >
        <input
          id="scheduler_stage_not_use_go_hosts_for_interviewers"
          type="radio"
          name="fountain"
          value="hire"
          checked={!usingGoInterviewHosts}
          onChange={() => setUseGoHostsForInterviews(false)}
          className={classes.optionButton}
        />
        <div className={classes.optionContent}>
          <span className={classes.optionTitle}>
            {intl.formatMessage(messages.fountainHireUsers)}
          </span>
          <span className={classes.optionDescription}>
            {intl.formatMessage(messages.fountainHireUsersDesc)}
          </span>
        </div>
      </label>

      <label
        htmlFor="scheduler_stage_use_go_hosts_for_interviewers"
        className={classes.optionDiv}
      >
        <input
          id="scheduler_stage_use_go_hosts_for_interviewers"
          type="radio"
          name="fountain"
          value="go"
          checked={usingGoInterviewHosts}
          onChange={() => setUseGoHostsForInterviews(true)}
          className={classes.optionButton}
        />
        <div className={classes.optionContent}>
          <span className={classes.optionTitle}>
            {intl.formatMessage(messages.fountainHireGoUsers)}
          </span>
          <span className={classes.optionDescription}>
            {intl.formatMessage(messages.fountainHireGoUsersDesc)}
          </span>
        </div>
      </label>
    </div>
  );
};
