import {
  WorkflowAccurateStage,
  WorkflowArchivedStage,
  WorkflowAssessmentStage,
  WorkflowBackgroundCheckerStage,
  WorkflowDataCollectionStage,
  WorkflowDocumentSignatureStage,
  WorkflowDocumentSigningStage,
  WorkflowI9FormStage,
  WorkflowJobMatcherStage,
  WorkflowMultiStage,
  WorkflowPartnerStage,
  WorkflowRejectedStage,
  WorkflowSchedulerStage,
  WorkflowSterlingStage,
  WorkflowW4FormStage,
} from 'api-clients/monolith';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../StageSettingCard';
import {
  GoEnabled,
  SchedulerStageGoEnabled,
} from '../WorkflowEditorContent/StageDetail/SettingsSwitches';
import { messages } from './messages';

type StagesWithAdvancedSettings =
  | WorkflowAccurateStage
  | WorkflowArchivedStage
  | WorkflowAssessmentStage
  | WorkflowBackgroundCheckerStage
  | WorkflowBackgroundCheckerStage
  | WorkflowDataCollectionStage
  | WorkflowDocumentSignatureStage
  | WorkflowDocumentSigningStage
  | WorkflowI9FormStage
  | WorkflowJobMatcherStage
  | WorkflowMultiStage
  | WorkflowPartnerStage
  | WorkflowRejectedStage
  | WorkflowSchedulerStage
  | WorkflowSterlingStage
  | WorkflowW4FormStage;
export const AdvancedSettings: VFC<{ stage: StagesWithAdvancedSettings }> = ({
  stage,
}) => {
  const intl = useIntl();
  const { setStage } = useContext(StageContext);

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.advancedSettings)}
    >
      {stage.type === 'SchedulerStage' ? (
        <SchedulerStageGoEnabled
          stage={stage}
          setStage={
            setStage as React.Dispatch<
              React.SetStateAction<WorkflowSchedulerStage>
            >
          }
        />
      ) : (
        <GoEnabled stage={stage} setStage={setStage} />
      )}
    </StageSettingCard>
  );
};
